import axios from "axios";
import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import API from "../../../api";

export default class Edit extends Component {
  constructor(props) {
    super(props);
    let self = this.props.location.state;
    this.state = {
      title: self.title || "",
      listOfLectures: self.listOfLectures,
      courseId: self.courseId,
      curriculumId: self.curriculumId,
      createSubTitle: [],
    };
  }
  handleSelectChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleBack(e) {
    this.props.history.goBack();
  }
  //old Ui functions
  handleOldChange(i, e) {
    const { name, value } = e.target;
    let listOfLectures = [...this.state.listOfLectures];

    listOfLectures[i] = { ...listOfLectures[i], [name]: value };
    this.setState({ listOfLectures });
  }

  handleOldChangePDF(i, e) {
    this.setState({ isloaded: true });
    const { name, value } = e.target;
    let listOfLectures = [...this.state.listOfLectures];

    listOfLectures[i] = { ...listOfLectures[i], [name]: value };
    this.setState({ listOfLectures });
  }

  handleOldFileChange(index, e) {
    const file = e.target.files[0];
    new API()
      .getHttpClient()
      .get("/course/thumbnail/upload")
      .then((res) => {
        if (res) {
          axios
            .put(`${res.data.url}`, file, {
              headers: {
                "content-type": file.type,
              },
            })
            .then((resA) => {
              toast.success("successfully uploaded!");
              this.handleOldChangePDF(index, {
                target: {
                  name: "fileName",
                  value: res.data.filename,
                },
              });
            })
            .catch((error) => {
              toast.error("something error!!!" + error);
            });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }

  handleUpdateOld(i, e) {
    let listOfLectures = [...this.state.listOfLectures];
    const apiConfig = {
      type: listOfLectures[i].type,
      title: listOfLectures[i].title,
    };
    if (listOfLectures[i].type.toLowerCase() === "video")
      apiConfig["videoLink"] = listOfLectures[i].videoLink;
    else apiConfig["fileName"] = listOfLectures[i].fileName;
    new API()
      .getHttpClient()
      .patch(`/curriculum/sub/${e}`, apiConfig)
      .then((res) => {
        if (res) {
          toast.success("updated!!");
          const old = JSON.parse(JSON.stringify(this.props.location.state));
          this.props.history.replace({
            state: {
              ...old,
              listOfLectures: this.state.listOfLectures,
            },
          });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }

  // old Ui
  createUI() {
    return this.state.listOfLectures.map((el, i) => (
      <div className="form-group row" key={i}>
        <label className="col-sm-2 col-form-label text-right">Sub Title</label>
        <div className="col-sm-5">
          <input
            className="form-control"
            type="text"
            placeholder="Eg- any topic of that chapter"
            name="title"
            value={el.title || ""}
            onChange={this.handleOldChange.bind(this, i)}
          />
        </div>
        <div className="col-sm-2">
          <select
            className="form-control"
            name="type"
            defaultValue={el.type}
            onChange={this.handleOldChangePDF.bind(this, i)}
          >
            <option selected disabled>
              Select
            </option>
            <option value="PDF">PDF</option>
            <option value="VIDEO">Video</option>
          </select>
        </div>

        <div className="col-sm-2">
          {el.type.toString() !== "PDF" ? (
            <input
              className="form-control"
              type="text"
              name="videoLink"
              value={el.videoLink || ""}
              onChange={this.handleOldChange.bind(this, i)}
            />
          ) : (
            <input
              type="file"
              className="form-control "
              name="fileName"
              onChange={this.handleOldFileChange.bind(this, i)}
            />
          )}
        </div>
        <div className="row">
          <button
            className="delete-btn"
            variant="contained"
            onClick={this.removeClick.bind(this, i, el.id)}
          >
            <span className="typcn typcn-delete"></span>
          </button>
          <button
            className="delete-btn rounded-2"
            variant="contained"
            onClick={this.handleUpdateOld.bind(this, i, el.id)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M15 9H5V5h10m-3 14a3 3 0 0 1-3-3a3 3 0 0 1 3-3a3 3 0 0 1 3 3a3 3 0 0 1-3 3m5-16H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V7l-4-4Z"
              />
            </svg>
          </button>
        </div>
      </div>
    ));
  }

  handleChangePDF(i, e) {
    const { name, value } = e.target;
    let values = [...this.state.values];

    values[i] = { ...values[i], [name]: value };
    this.setState({ values });
  }
  handleNewFileChange(i, e) {
    new API()
      .getHttpClient()
      .get("/course/thumbnail/upload")
      .then((res) => {
        if (res) {
          axios
            .put(`${res.data.url}`, e.target.files[0], {
              headers: {
                "content-type": e.target.files[0].type,
              },
            })
            .then((resA) => {
              toast.success("successfully uploaded!");
              this.setState({ subtitleUrl: res.data.filename });
            })
            .catch((error) => {
              toast.error("something error!" + error);
            });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }
  addClick() {
    this.setState((prevState) => ({
      createSubTitle: [...prevState.createSubTitle, ""],
    }));
    this.setState({ toggle: true });
  }

  removeNewClick(i) {
    let values = [...this.state.values];
    // if (values[i].subtitleUrl) {
    //   new API()
    //     .getHttpClient()
    //     .post(`/storage/deleteFile?fileUrl=${values[i].subtitleUrl}`)
    //     .then((res) => {
    //       if (res) {
    //         values.splice(i, 1);
    //         this.setState({ values });
    //       }
    //     })
    //     .catch((error) => {
    //       toast.error("error !!" + error);
    //     });
    // } else {
    //   values.splice(i, 1);
    //   this.setState({ values });
    // }
  }
  //end field

  //curriculam create
  handleAddSubtitle() {
    this.setState((prevState) => ({
      createSubTitle: [...prevState.createSubTitle, { title: "", type: "PDF" }],
    }));
  }
  createSubTitle() {
    return this.state.createSubTitle.map((el, i) => (
      <div className="form-group row" key={i}>
        <label className="col-sm-2 col-form-label text-right">Sub Title</label>
        <div className="col-sm-4">
          <input
            className="form-control"
            type="text"
            placeholder="Eg- any topic of that chapter"
            name="title"
            value={el.title || ""}
            onChange={this.handleTitleChange.bind(this, i)}
          />
        </div>
        <div className="col-sm-2">
          <select
            className="form-control"
            name="type"
            defaultValue={this.state.type}
            onChange={this.handleChangePDF.bind(this, i)}
          >
            <option selected disabled>
              Select
            </option>
            <option value="PDF">PDF</option>
            <option value="VIDEO">Video</option>
          </select>
        </div>
        <div className="col-sm-3">
          {el.type === "VIDEO" ? (
            <input
              className="form-control"
              type="text"
              name="videoLink"
              value={el.videoLink || el.fileName || ""}
              onChange={this.handleTitleChange.bind(this, i)}
            />
          ) : (
            <input
              type="file"
              className="form-control "
              name="subtitleUrl"
              onChange={this.handleFileChange.bind(this, i)}
            />
          )}
        </div>
        <div className="row">
          <button
            className="delete-btn"
            variant="contained"
            onClick={this.removeClick.bind(this, i, el.id)}
          >
            <span className="typcn typcn-delete"></span>
          </button>
          <button
            className="btn btn-success btn-sm rounded-2"
            variant="contained"
            onClick={this.addSubClick.bind(this, el.id, el.title, el)}
          >
            <span className="fas fa-check"></span>
          </button>
        </div>
      </div>
    ));
  }
  handleTitleChange(i, e) {
    const { name, value } = e.target;
    let createSubTitle = [...this.state.createSubTitle];

    createSubTitle[i] = { ...createSubTitle[i], [name]: value };
    this.setState({ createSubTitle });
  }
  handleVideoTextChange(index, value) {
    let createSubTitle = [...this.props.location.state.listOfLectures];
    createSubTitle[index].subtitleUrl = value;

    this.setState({
      listOfLectures: createSubTitle,
    });
  }
  handleChangePDFInfo(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleFileChange(index, e) {
    const file = e.target.files[0];
    new API()
      .getHttpClient()
      .get("/course/thumbnail/upload")
      .then((res) => {
        if (res) {
          axios
            .put(`${res.data.url}`, file, {
              headers: {
                "content-type": file.type,
              },
            })
            .then((resA) => {
              toast.success("successfully uploaded!");
              this.handleChangePDF(index, {
                target: {
                  name: "fileName",
                  value: res.data.filename,
                },
              });
              // this.setState({ createSubTitle: res.data.filename });
            })
            .catch((error) => {
              toast.error("something error!!!" + error);
            });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });

    // const formData = new FormData();
    // formData.append("file", e.target.files[0]);
    // const config = {
    //   headers: {
    //     "content-type": "multipart/form-data",
    //   },
    // };
    // new API()
    //   .getHttpClient()
    //   .post("/storage/uploadFile", formData, config)
    //   .then((res) => {
    //     if (res) {
    //       toast.success("Successfully Uploaded");
    //       let createSubTitle = [...this.props.location.state.listOfLectures];
    //       createSubTitle[index].subtitleUrl = res.data;

    //       this.setState({
    //         listOfLectures: createSubTitle,
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     toast.error("something error!" + error);
    //   });
  }
  handleChangePDF(i, e) {
    this.setState({ isloaded: true });
    const { name, value } = e.target;
    let createSubTitle = [...this.state.createSubTitle];

    createSubTitle[i] = { ...createSubTitle[i], [name]: value };
    this.setState({ createSubTitle });
  }

  removeClick(i, id) {
    swal({
      title: "Are you sure?",
      text: `You want to delete sub Title from the Curriculam`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        new API()
          .getHttpClient()
          .delete("curriculum/sub/" + id)
          .then((res) => {
            toast.success("successfull Deleted");
            const old = JSON.parse(JSON.stringify(this.props.location.state));
            this.props.history.replace({
              state: {
                ...old,
                listOfLectures: this.state.listOfLectures.filter(
                  (lecture) => lecture.id.toString() != id.toString()
                ),
              },
            });
            this.setState({
              listOfLectures: this.state.listOfLectures.filter(
                (lecture) => lecture.id.toString() != id.toString()
              ),
              createSubTitle: [],
            });
          })
          .catch((error) => {
            toast.error("something error!" + error);
          });
      }
    });
  }
  addSubClick(id, title, el) {
    const apiConfig = {
      courseId: this.state.courseId,
      title: title,
      curriculumId: this.state.curriculumId,
      type: el.type,
    };
    if (el.type.toLowerCase() === "video")
      apiConfig["videoLink"] = el.videoLink;
    else apiConfig["fileName"] = el.fileName;
    new API()
      .getHttpClient()
      .post("/curriculum/sub", apiConfig)
      .then((res) => {
        if (res) {
          toast.success("Added Successfully!");
          const old = JSON.parse(JSON.stringify(this.props.location.state));
          this.props.history.replace({
            state: {
              ...old,
              listOfLectures: [
                ...this.state.listOfLectures,
                {
                  courseId: this.state.courseId,
                  title: title,
                  curriculumId: this.state.curriculumId,
                  type: el.type,
                  fileName: el.fileName,
                  videoLink: el.videoLink,
                  id: res.data.id,
                },
              ],
            },
          });
          this.setState({
            listOfLectures: [
              ...this.state.listOfLectures,
              {
                courseId: this.state.courseId,
                title: title,
                curriculumId: this.state.curriculumId,
                type: el.type,
                fileName: el.fileName,
                videoLink: el.videoLink,
                id: res.data.id,
              },
            ],
            createSubTitle: [],
          });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }

  //end curriculam

  handleUpdateDetails = async (event) => {
    event.preventDefault();
    var newArr;
    let { listOfLectures, values } = this.state;
    if (this.props.location.maindata.id) {
      if (listOfLectures) {
        newArr = listOfLectures.concat(values);
        await new API()
          .getHttpClient()
          .post("/course/updateCourse", {
            id: this.props.location.maindata.id,
            lectureEmbeddedCourse: {
              id: this.props.location.state.id,
              title: this.state.title,
              listOfLectures: newArr,
            },
          })
          .then((res) => {
            if (res) {
              toast.success("Successfully Update");
              this.props.history.goBack();
            }
          })
          .catch((error) => {
            toast.error("error !!" + error);
          });
      } else {
        newArr = this.props.location.state.listOfLectures.concat(values);
        await new API()
          .getHttpClient()
          .post("/course/updateCourse", {
            id: this.props.location.maindata.id,
            lectureEmbeddedCourse: {
              id: this.props.location.state.id,
              title: this.state.title,
              listOfLectures: newArr,
            },
          })
          .then((res) => {
            if (res) {
              toast.success("Successfully Update");
              this.props.history.goBack();
            }
          })
          .catch((error) => {
            toast.error("error !!" + error);
          });
      }
    } else {
      toast.error("Id is undefined!");
    }
  };

  handleUpdateTitle = async (event) => {
    event.preventDefault();
    console.log({
      curriculumId: this.state.curriculumId,
      title: this.state.title,
    });
    await new API()
      .getHttpClient()
      .patch(`/curriculum/main/${this.state.curriculumId}`, {
        title: this.state.title,
      })
      .then((res) => {
        if (res) {
          toast.success("Successfully Update");
        }
      })
      .catch((error) => {
        toast.error("error !!" + error);
      });
  };
  render() {
    return (
      <div>
        <div className="page-head">
          <div className="row">
            <div className="col-sm-6 text-head">
              <h3>Update Curriculam</h3>
            </div>
            <div className="col-sm-6 text-right">
              <button
                type="button"
                className="btn btn-dark"
                onClick={(e) => this.handleBack(e)}
              >
                <span className="typcn typcn-arrow-left"></span> Go Back
              </button>
            </div>
          </div>
        </div>
        <div className="page-wrapper">
          <div className="course-cur-card">
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" name="title">
                Heading Title
              </label>
              <div className="col-sm-5">
                <div className="d-inline-flex w-75 align-items-center">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Eg- Chapter-1, Part-1 or any title"
                    name="title"
                    value={this.state.title}
                    onChange={(e) => this.handleSelectChange(e)}
                  />
                  <button
                    className="delete-btn rounded-2"
                    variant="contained"
                    onClick={(e) => this.handleUpdateTitle(e)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M15 9H5V5h10m-3 14a3 3 0 0 1-3-3a3 3 0 0 1 3-3a3 3 0 0 1 3 3a3 3 0 0 1-3 3m5-16H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V7l-4-4Z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              {/* <button
                className="delete-btn rounded-2"
                variant="contained"
                // onClick={this.removeClick.bind(this, i, el.id)}
              >
                <span className="typcn typcn-edit"></span>
              </button> */}
            </div>
            {this.createUI()}
            {this.createSubTitle()}
            <button
              type="button"
              className="btn btn-success-soft w-100p ml-5"
              onClick={this.addClick.bind(this)}
            >
              Add Sub-Title
            </button>
          </div>

          <ToastContainer autoClose={1500} />
        </div>
      </div>
    );
  }
}
