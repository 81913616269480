import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import API from "../../../api";

export default class Create extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, name: "", value: "", courseList: [] };
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleBack(e) {
    this.props.history.goBack();
  }
  componentDidMount() {
    new API()
      .getHttpClient()
      .get("/course/getCourseNameAndIds")
      .then((res) => {
        if (res) {
          this.setState({ courseList: res.data });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }
  handleSave = async (event) => {
    event.preventDefault();
    const { name, value, CouponCourse } = this.state;
    try {
      await new API()
        .getHttpClient()
        .post("/coupon/createCoupon", {
          name: name,
          value: value,
          courseId: CouponCourse,
        })
        .then((res) => {
          if (res) {
            toast.success("Successfully Created Coupon");
          }
        })
        .catch((error) => {
          toast.error("error !!" + error);
        });
    } catch (e) {
      toast.error("Please check input value !!");
    }
  };

  render() {
    const { courseList } = this.state;
    return (
      <div>
        {/* Page Head */}
        <div className="page-head">
          <div className="row">
            <div className="col-sm-6 text-head">
              <h3>Create New Coupon</h3>
            </div>

            <div className="col-sm-6 text-right">
              <button
                type="button"
                className="btn btn-dark"
                onClick={(e) => this.handleBack(e)}
              >
                <span className="typcn typcn-arrow-left" /> Go Back
              </button>
            </div>
          </div>
        </div>
        {/* Page Head */}

        <section className="page-wrapper">
          <div className="sub-heads row">
            <div className="col-sm-6 text-head">
              <h5>Please enter the coupon details below</h5>
            </div>

            <div className="col-sm-6 text-right"></div>
          </div>

          <div className="row">
            <div className="col-md-4 form-group">
              <label>Coupon Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={this.state.name}
                onChange={(e) => this.handleChange(e)}
              />
            </div>

            <div className="col-md-4 form-group">
              <label>Coupon Value</label>
              <input
                type="number"
                className="form-control"
                name="value"
                value={this.state.value}
                onChange={(e) => this.handleChange(e)}
              />
            </div>

            <div className="col-md-4 form-group">
              <label>Coupon Course</label>
              <select
                className="form-control"
                name="CouponCourse"
                value={this.state.CouponCourse}
                onChange={(e) => this.handleChange(e)}
              >
                <option selected>select course</option>
                {courseList
                  ? courseList.map((row, index) => (
                      <option key={index} value={row.id}>
                        {row.courseName}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
          </div>

          <button
            className=" btn btn-success col-sm-3 mt-3 py-2"
            onClick={this.handleSave}
          >
            Save Coupon
          </button>
          <ToastContainer autoClose={1500} />
        </section>
      </div>
    );
  }
}
