import { format } from "date-fns";
import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../api";
export default class Newsletter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
  }
  async componentDidMount() {
    new API()
      .getHttpClient()
      .post("/blog/getAllNewsLetter")
      .then((res) => {
        if (res) {
          this.setState({ list: res.data });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }
  render() {
    const { list } = this.state;
    console.log(list);
    return (
      <div>
        <div className="wrapper">
          <div className="content-wrapper">
            <div className="main-content">
              {/* Page Head */}
              <div className="page-head">
                <div className="row">
                  <div className="col-sm-6 text-head">
                    <h3>Newsletter Users</h3>
                  </div>

                  <div className="col-sm-6 text-right"></div>
                </div>
              </div>
              {/* Page Head */}

              <div className="page-wrapper">
                <div className="sub-heads row">
                  <div className="col-sm-5 text-head">
                    <h5>Showing all newsletter users</h5>
                  </div>

                  <div className="col-sm-7 text-right"></div>
                </div>

                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Email Id</th>
                      </tr>
                    </thead>

                    <tbody>
                      {list
                        ? list.map((row, index) => (
                            <tr key={index}>
                              <td>{+index}</td>
                              <td>
                                {format(new Date(row.date), "dd/MM/yyyy")}
                              </td>
                              <td>{row.emailId}</td>
                            </tr>
                          ))
                        : "No data found"}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
