const API_URL = "/v1";

const Apis = {
  //course
  GetAllWorkshop: `${API_URL}/api/course/getAllCourses?courseType=1`,
  GetAllLiveSessionDetails: `${API_URL}/getAllCourses?courseType=2`,
  GetCurriculamVideoUrl: `${API_URL}/api/course/getCourseById?id=`,

  //master class
  GetAllMasterClassRecorded: `${API_URL}/course/recorded-masterclass`,
  GetAllMasterLiveSession: `${API_URL}/course/live-masterclass`,

  //blogs
  GetAllBlogsList: `${API_URL}/getAllBlog`,
};
export { API_URL, Apis };
