import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../../api";

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;
  // validate form errors being empty
  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  Object.values(rest).forEach((val) => {
    val === null && (valid = false);
  });

  return valid;
};
export default class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloaded: false,
      loading: true,
      email: null,
      firstName: null,
      lastName: null,
      decentralization: 1,
      teacherPhotoUrl: "",
      teacherDescription: "",
      uploadPercentage: 0,
      formErrors: {
        email: "",
        firstName: "",
        lastName: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }
  handleChange(e) {
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };

    switch (name) {
      case "email":
        formErrors.email = emailRegex.test(value)
          ? ""
          : "invalid email address";
        break;
      case "firstName":
        formErrors.firstName = value.length < 1 ? " FirstName required" : "";
        break;
      case "lastName":
        formErrors.lastName = value.length < 1 ? " LastName required" : "";
        break;
      default:
        break;
    }

    this.setState({ formErrors, [name]: value });
  }
  handleBack(e) {
    this.props.history.goBack();
  }
  UploadFile(e) {
    this.setState({ loading: false });
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        console.log(`${loaded}kb of ${total}kb | ${percent}%`);

        if (percent < 100) {
          this.setState({ uploadPercentage: percent });
        }
      },
    };

    new API()
      .getHttpClient()
      .post("/storage/uploadFile", formData, config, options)
      .then((res) => {
        if (res) {
          this.setState(
            { teacherPhotoUrl: res.data, uploadPercentage: 100 },
            () => {
              setTimeout(() => {
                this.setState({ uploadPercentage: 0, loading: true });
              }, 4000);
            }
          );
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }
  handleUpdate(event) {
    event.preventDefault();
    let { email, firstName, lastName } = this.state;
    if (formValid(this.state)) {
      new API()
        .getHttpClient()
        .post("/user/addAdmin", {
          email: email,
          firstName: firstName,
          lastName: lastName,
          decentrizationId: this.state.decentralization,
          teacherDescription: this.state.teacherDescription,
          teacherPhotoUrl: this.state.teacherPhotoUrl,
        })
        .then((res) => {
          this.setState({ loading: true, userdata: res.data });
          window.location = "/admin/user/list";
        })
        .catch((error) => {
          toast.error("error !!" + error);
        });
    } else {
      toast.error("Input Field is requeired", "Input");
    }
  }
  render() {
    const { formErrors, uploadPercentage, loading } = this.state;
    return (
      <Paper>
        <div className="page-head">
          <div className="row">
            <div className="col-sm-6 text-head">
              <h3>Add New User</h3>
            </div>
          </div>
        </div>

        <section className="panel">
          <div className="sub-heads row">
            <div className="col-sm-6 text-head">
              <h5>Showing user information</h5>
            </div>

            <div className="col-sm-6 text-right">
              <button
                className=" btn btn-light"
                onClick={(e) => this.handleBack(e)}
              >
                <span className="typcn typcn-arrow-left-thick"></span>Back
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 form-group">
              <label>First Name</label>
              <input
                type="text"
                className="form-control "
                name="firstName"
                value={this.state.firstName}
                onChange={this.handleChange}
              />
              {formErrors.firstName.length > 0 && (
                <span className="text-danger">{formErrors.firstName}</span>
              )}
            </div>

            <div className="col-md-6 form-group">
              <label>Last Name</label>
              <input
                type="text"
                className="form-control "
                name="lastName"
                value={this.state.lastName}
                onChange={this.handleChange}
              />
              {formErrors.lastName.length > 0 && (
                <span className="text-danger">{formErrors.lastName}</span>
              )}
            </div>

            <div className="form-group col-md-6">
              <label>Email</label>
              <input
                type="text"
                className="form-control "
                id="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
              {formErrors.email.length > 0 && (
                <span className="text-danger">{formErrors.email}</span>
              )}
            </div>

            <div className="col-md-6 form-group">
              <label>Role</label>
              <select
                id="role"
                className="form-control"
                name="decentralization"
                defaultValue={this.state.decentralization}
                onChange={this.handleChange}
              >
                <option value="1">Admin</option>
                <option value="3">Teacher</option>
              </select>
            </div>

            <div
              className="w-100"
              style={
                this.state.decentralization === "3"
                  ? { display: "flex" }
                  : { display: "none" }
              }
            >
              <div className="col-md-6 form-group">
                <label>About Teacher</label>
                <textarea
                  rows="4"
                  className="form-control"
                  type="text"
                  placeholder="Describe your live session"
                  name="teacherDescription"
                  value={this.state.teacherDescription}
                  onChange={this.handleChange}
                ></textarea>
              </div>

              <div classname="col-md-6 form-group">
                <label>Teacher Image</label>
                <input
                  type="file"
                  className="form-control"
                  name="teacherPhotoUrl"
                  onChange={this.UploadFile.bind(this)}
                />
                {uploadPercentage > 0 && (
                  <CircularProgress active value={`${uploadPercentage}%`} />
                )}
              </div>
            </div>
          </div>

          <button
            className={
              loading ? "btn btn-success col-sm-3 mt-3 py-2" : "d-none"
            }
            onClick={this.handleUpdate}
          >
            Add New
          </button>
          <ToastContainer autoClose={1500} />
        </section>
      </Paper>
    );
  }
}
