import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RichTextEditor from "../../../RichTextEditor";
import API from "../../../api";

export default class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: ``,
      thumbnailUrl: "",
      title: "",
    };
  }
  handleSelectChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleContentChange = (contentHtml) => {
    this.setState({
      content: contentHtml,
    });
  };
  UploadFile(e) {
    this.setState({ isloaded: false });
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    new API()
      .getHttpClient()
      .post("/storage/uploadFile", formData, config)
      .then((res) => {
        if (res) {
          this.setState({ thumbnailUrl: res.data });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }
  handleSaveDetails = (event) => {
    event.preventDefault();
    const {
      thumbnailUrl,
      title,
      description,
      metaTitle,
      metaDescription,
      content,
      categoryId,
    } = this.state;
    if (title) {
      new API()
        .getHttpClient()
        .post("/blog/createBlog", {
          title: title,
          imageUrl: thumbnailUrl,
          description: content,
          categoryId: categoryId,
          metaTitle: metaTitle,
          metaDescription: metaDescription,
          details: description,
        })
        .then((res) => {
          toast.success("Successfully inserted data");
          if (res) {
            toast.success("Successfully Added New Course");
            this.props.history.goBack();
          }
        })
        .catch((error) => {
          toast.error("error !!" + error);
        });
    }
  };
  render() {
    return (
      <div>
        {/* Page Head */}
        <div className="page-head">
          <div className="row">
            <div className="col-sm-6 text-head">
              <h3>Create New Blog</h3>
            </div>

            <div className="col-sm-6 text-right">
              <button
                type="button"
                className="btn btn-dark"
                onClick={(e) => this.handleBack(e)}
              >
                <span className="typcn typcn-arrow-left" /> Go Back
              </button>
              <button
                type="button"
                className="btn btn-labeled btn-success ml-2"
                onClick={this.handleSaveDetails}
              >
                <span className="btn-label">
                  <i className="fas fa-check"></i>
                </span>
                Save Blog
              </button>
            </div>
          </div>
        </div>
        {/* Page Head */}

        <section className="page-wrapper">
          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-sm-3 col-form-label"
            >
              Blog Title
            </label>
            <div className="col-sm-9">
              <input
                className="form-control"
                type="text"
                placeholder="Enter you live session title"
                name="title"
                value={this.state.title}
                onChange={(e) => this.handleSelectChange(e)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-sm-3 col-form-label"
            >
              Category
            </label>
            <div className="col-sm-9">
              <select
                className="form-control"
                name="categoryId"
                value={this.state.categoryId}
                onChange={(e) => this.handleSelectChange(e)}
              >
                <option>Select</option>
                <option value="1">Vaidic Astrology</option>
                <option value="2">Ayurveda</option>
                <option value="3">Health & Wellness</option>
                <option value="4">Recipies</option>
                <option value="5">Wisdom & Spirituality</option>
                <option value="6">Yoga & Meditation</option>
                <option value="7">Mindfulness</option>
                <option value="8">Articles</option>
              </select>
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-sm-3 col-form-label"
            >
              Meta Title
            </label>
            <div className="col-sm-9">
              <input
                className="form-control"
                type="text"
                placeholder="Enter Meta Title"
                name="metaTitle"
                value={this.state.metaTitle}
                onChange={(e) => this.handleSelectChange(e)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-sm-3 col-form-label"
            >
              Meta Description
            </label>
            <div className="col-sm-9">
              <input
                className="form-control"
                type="text"
                placeholder="Enter Meta Description"
                name="metaDescription"
                value={this.state.metaDescription}
                onChange={(e) => this.handleSelectChange(e)}
              />
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="example-text-input"
              className="col-sm-3 col-form-label"
            >
              Description
            </label>
            <textarea
              rows="4"
              className="form-control"
              type="text"
              placeholder="Describe your live session"
              name="description"
              value={this.state.description}
              onChange={(e) => this.handleSelectChange(e)}
            />
          </div>

          <div className="form-group row">
            <label
              htmlFor="example-number-input"
              className="col-sm-3 col-form-label"
            >
              Image Thumbnail
            </label>
            <div className="col-sm-9" style={{ display: "flex" }}>
              <input
                type="file"
                className="form-control"
                name="thumbnailUrl"
                onChange={this.UploadFile.bind(this)}
              />
            </div>
          </div>

          <h6 className="font-weight-bold mb-3">About the course</h6>

          <RichTextEditor
            content={this.state.content}
            handleContentChange={this.handleContentChange}
            placeholder="insert text here..."
          />
        </section>
      </div>
    );
  }
}
