import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import List from "./list";
import Edit from "./edit";

export default class Workshop extends Component {
  render() {
    const { match } = this.props;
    return (
      <main>
        <div className="wrapper">
          <div className="content-wrapper">
            <div className="main-content">
              <Switch>
                <Route path={`${match.path}/list`} component={List} />
                <Route path={`${match.path}/edit`} component={Edit} />
              </Switch>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
