import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@material-ui/core";
import { format } from "date-fns";
import React, { Component } from "react";
import Loader from "react-loader";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import API from "../../../api";

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      course: [],
      usersList: [],
      isLoaded: true,
      openModel: false,
      courseId: "",
      userId: "",
      openAddCustomer: false,
      email: "",
      mobile: "",
      firstName: "",
      lastName: "",
      gender: "",
    };
  }
  handleBack(e) {
    this.props.history.goBack();
  }
  handlMember(e) {
    this.setState({ member: e.target.value });
  }
  async componentDidMount() {
    this.setState({ isLoaded: true });
    await new API()
      .getHttpClient()
      .get("/user")
      .then(async (res) => {
        await new API()
          .getHttpClient()
          .get("/course/all")
          .then(async (resCourse) => {
            await new API()
              .getHttpClient()
              .get("/user/all")
              .then((users) => {
                this.setState({
                  isLoaded: false,
                  list: res.data.data,
                  course: resCourse.data,
                  usersList: users.data,
                });
              })
              .catch((error) => {
                toast.error("something error!" + error);
              });
          })
          .catch((error) => {
            toast.error("something error!" + error);
          });
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }
  async handlDeleteById(row) {
    swal({
      title: "Are you sure?",
      text: `You want to delete user ${row.email} from the List`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        new API()
          .getHttpClient()
          .delete("/user/" + row.id)
          .then((res) => {
            toast.success("successfull Deleted");
            window.location.reload();
          })
          .catch((error) => {
            toast.error("something error!" + error);
          });
      }
    });
  }
  handleCustomerModal() {
    this.setState((state) => {
      return {
        openAddCustomer: !state.openAddCustomer,
      };
    });
  }
  handleModal() {
    this.setState((state) => {
      console.log(state);
      return {
        openModel: !state.openModel,
      };
    });
  }
  async handleAddAssign() {
    await new API()
      .getHttpClient()
      .post("/user-course/course", {
        courseId: this.state.courseId,
        userId: this.state.userId,
      })
      .then((res) => {
        toast.success("cource added for user!");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.error(error);
        toast.error("something error!");
      });
  }
  async handleSubmitMember() {
    this.setState({ isLoaded: true });
    await new API()
      .getHttpClient()
      .get(`/user?query=${this.state.member}`)
      .then(async (res) => {
        this.setState({
          isLoaded: false,
          list: res.data.data,
        });
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }
  async handleAddUser(e, state) {
    e.preventDefault();
    console.log(state);
    await new API()
      .getHttpClient()
      .post("/user", {
        email: state.email,
        password: state.password,
        firstName: state.firstName,
        lastName: state.lastName,
        gender: state.gender,
      })
      .then((res) => {
        console.log(res);
        toast.success("user added!");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        let errorMessage = error?.response?.data?.message;
        if (typeof error?.response?.data?.message == "object") {
          console.log(errorMessage);
          errorMessage = errorMessage?.join();
        }

        toast.error(errorMessage || "something error!");
      });
  }
  render() {
    let self = this.state.list;
    let isLoaded = this.state.isLoaded;
    return (
      <div>
        {isLoaded ? (
          <div>
            <Loader />
          </div>
        ) : (
          <div>
            <div className="page-head">
              <div className="row">
                <div className="d-inline-flex justify-content-between w-100">
                  <h3>Customer Management</h3>
                  <div className="d-inline-flex justify-content-between">
                    <button
                      className="btn btn-success m-1"
                      onClick={() => this.handleCustomerModal()}
                    >
                      Add Customer
                    </button>
                    <button
                      className="btn btn-success m-1"
                      onClick={() => this.handleModal()}
                    >
                      Assign Course
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="page-wrapper">
              <div className="sub-heads row">
                <div className="col-sm-5 text-head">
                  <h5>Showing customers list</h5>
                </div>

                <div className="col-sm-7 text-right">
                  <div className="row justify-content-end">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="member"
                        className="form-control"
                        placeholder="search..."
                        value={this.state.member}
                        onChange={(e) => this.handlMember(e)}
                      />
                    </div>

                    <div className="col-md-2 p-0">
                      <button
                        type="button"
                        className="btn btn-success w-100"
                        onClick={(e) => this.handleSubmitMember(e)}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive cust-table">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>First Name </th>
                      <th>Last Name </th>
                      <th>Phone No </th>
                      <th>Gender </th>
                      <th>Email </th>
                      <th>Role </th>
                      <th>Newsletter</th>
                      <th>Action </th>
                    </tr>
                  </thead>
                  {this.state.isLoaded ? (
                    <p>loading</p>
                  ) : (
                    <tbody>
                      {self.map((row, i) => (
                        <tr key={i}>
                          <td>{++i}</td>
                          <td>
                            <b>
                              {format(new Date(row.createdAt), "dd/MM/yyyy")}
                            </b>
                          </td>
                          <td>{row.firstName}</td>
                          <td>{row.lastName}</td>
                          <td>{row.phone}</td>
                          <td>{row.gender == "f" ? "FEMALE" : "MALE"}</td>
                          <td>{row.email}</td>
                          <td>{row.role}</td>
                          <td align="center">
                            {row.newsFeedEnable ? "yes" : "No"}
                          </td>
                          <td style={{ display: "-webkit-box" }}>
                            <Link
                              to={{
                                pathname: `/admin/customer/edit`,
                                state: row,
                              }}
                            >
                              <button
                                variant="contained"
                                className="actions-btn"
                              >
                                <span className="typcn typcn-edit"></span>
                              </button>
                            </Link>
                            <button
                              className="actions-btn"
                              onClick={(e) => this.handlDeleteById(row)}
                            >
                              <i className="fas fa-trash-alt" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        )}
        <Modal
          open={this.state.openModel}
          onClose={() => this.handleModal()}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "100vw",
              maxWidth: "600px",
              height: "330",
              maxHeight: "70vh",
              background: "#fff",
              padding: "20px",
              position: "absolute",
              boxShadow: "10px",
            }}
          >
            <Grid container spacing={2} direction="column">
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="Customer">Customer</InputLabel>
                  <Select
                    labelId="Customer"
                    id="Customer"
                    value={this.state.userId}
                    onChange={(e) => {
                      this.setState({
                        userId: e.target.value,
                      });
                    }}
                    label="Customer"
                  >
                    {this.state.usersList.map((user) => (
                      <MenuItem value={user.id}>
                        {user.firstName} {user.lastName} {`(${user.email})`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item fullWidth xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="Courses">Courses</InputLabel>
                  <Select
                    labelId="Courses"
                    id="demo-simple-select-outlined"
                    value={this.state.courseId}
                    onChange={(e) => {
                      this.setState({
                        courseId: e.target.value,
                      });
                    }}
                    label="Courses"
                  >
                    {this.state.course.map((cours) => (
                      <MenuItem value={cours.id}>{cours.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} fullWidth>
                <div className="d-inline-flex w-100 flex-row-reverse">
                  <button
                    className="btn btn-success"
                    onClick={() => this.handleAddAssign()}
                  >
                    Add
                  </button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Modal>

        <Modal
          open={this.state.openAddCustomer}
          onClose={() => this.handleCustomerModal()}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "100vw",
              maxWidth: "600px",
              height: "330",
              maxHeight: "70vh",
              background: "#fff",
              padding: "20px",
              position: "absolute",
              boxShadow: "10px",
            }}
          >
            <form onSubmit={(e) => this.handleAddUser(e, this.state)}>
              <Grid container spacing={2} md={12} sm={12} lg={12} xs={12}>
                <Grid item xs={6} md={6} sm={6}>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    // value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </Grid>
                <Grid item fullWidth xs={6} md={6} sm={6}>
                  <TextField
                    type="password"
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    // value={this.state.password}
                    onChange={(e) =>
                      this.setState({
                        password: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6} md={6} sm={6}>
                  <TextField
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    // value={this.state.firstName}
                    onChange={(e) =>
                      this.setState({
                        firstName: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item fullWidth xs={6} md={6} sm={6}>
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    // value={this.state.lastName}
                    onChange={(e) =>
                      this.setState({
                        lastName: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} fullWidth>
                  <Grid item fullWidth xs={6} md={6} sm={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="genderId">Gender</InputLabel>
                      <Select
                        labelId="genderId"
                        id="demo-simple-select-filled"
                        label="Gender"
                        // value={this.state.gender}
                        onChange={(e) =>
                          this.setState({
                            gender: e.target.value,
                          })
                        }
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="m">Male</MenuItem>
                        <MenuItem value="f">FeMale</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <div className="d-inline-flex w-100 flex-row-reverse">
                  <button className="btn btn-success" type="submit">
                    Add
                  </button>
                </div>
              </Grid>
            </form>
          </div>
        </Modal>

        <ToastContainer autoClose={1500} />
      </div>
    );
  }
}
