import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import API from "../../../api";

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      courseList: [],
    };
  }
  async getCourse() {
    new API()
      .getHttpClient()
      .post("/courseCombo/getAllCourseCombo")
      .then((res) => {
        if (res) {
          this.setState({ list: res.data });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }
  componentDidMount() {
    this.getCourse();

    new API()
      .getHttpClient()
      .get("/course/getCourseNameAndIds")
      .then((res) => {
        if (res) {
          this.setState({ courseList: res.data });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }
  async handlDeleteById(row) {
    swal({
      title: "Are you sure?",
      text: `You want to delete combooffer from the List`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        new API()
          .getHttpClient()
          .post("/courseCombo/deleteCourseComboById?id=" + row.id)
          .then((res) => {
            toast.success("successfull Deleted");
            window.location.reload();
          })
          .catch((error) => {
            toast.error("something error!" + error);
          });
      }
    });
  }

  render() {
    let { list, courseList } = this.state;
    console.log(list);
    return (
      <div>
        {/* Page Head */}
        <div className="page-head">
          <div className="row">
            <div className="col-sm-6 text-head">
              <h3>Combo Offers </h3>
            </div>

            <div className="col-sm-6 text-right">
              <a href="/admin/combo-offers/create" className="btn btn-success">
                {" "}
                Create combo-offer{" "}
              </a>
            </div>
          </div>
        </div>
        {/* Page Head */}

        <div className="page-wrapper">
          <div className="sub-heads row">
            <div className="col-sm-5 text-head">
              <h5>Showing all combo-offer list</h5>
            </div>

            <div className="col-sm-7 text-right"></div>
          </div>

          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>First course</th>
                  <th>Second Course </th>
                  <th>Indian Price </th>
                  <th>Other Price </th>
                  <th>Action </th>
                </tr>
              </thead>
              <tbody>
                {list.length
                  ? list.map((row, i) => (
                      <tr key={i}>
                        <td>{++i}</td>
                        <td>
                          {courseList
                            ? courseList.map((p, index) =>
                                p.id === row.firstCourseId ? (
                                  <span key={index}>{p.courseName}</span>
                                ) : (
                                  ""
                                )
                              )
                            : ""}
                        </td>
                        <td>
                          {courseList
                            ? courseList.map((p, index) =>
                                p.id === row.secondCourseId ? (
                                  <span key={index}>{p.courseName}</span>
                                ) : (
                                  ""
                                )
                              )
                            : ""}
                        </td>
                        <td>{row.indianPrice}</td>
                        <td>{row.otherPrice}</td>
                        <td>
                          <button
                            className="actions-btn"
                            onClick={(e) => this.handlDeleteById(row)}
                          >
                            <i className="fas fa-trash-alt" />
                          </button>
                        </td>
                      </tr>
                    ))
                  : "No Data found"}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
