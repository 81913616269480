import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import API from '../../../api';

export default class Edit extends Component {
    constructor(props) {
        super(props);
        let self = this.props.location.state;
        this.state = {
            userId: self.userId, courseId: self.courseId,
            id: self.id, name: self.answer, value: self.question
        }
    }
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleBack(e) {
        this.props.history.goBack();
    }
    handleUpdateDetails = async (event) => {
        event.preventDefault();
        const { id, name, value, userId, courseId } = this.state;
        await new API().getHttpClient().post('/comment/updateComment', {
            id: id, question: value, answer: name, userId: userId, courseId: courseId
        }).then((res) => {
            if (res) {
                toast.success("Successfully Update");
                this.props.history.goBack();
            }
        })
            .catch(error => {
                toast.error("error !!" + error)
            })
    }
    render() {
        let { value, name} = this.state;
        return (
            <div>

                {/* Page Head */}
                <div className="page-head">
                    <div className="row">
                        <div className="col-sm-6 text-head">
                            <h3>Reply Comment</h3>
                        </div>

                        <div className="col-sm-6 text-right">
                            <button type="button" className="btn btn-dark"><span className="typcn typcn-arrow-left" /> Go Back</button>
                        </div>

                    </div>
                </div>
                {/* Page Head */}


                <section className="page-wrapper">

                    <div className="sub-heads row">
                        <div className="col-sm-6 text-head">
                            <h5>Reply answer below for the mentioned question.</h5>
                        </div>

                        <div className="col-sm-6 text-right">

                        </div>
                    </div>




                    <div className="row">

                        <div className="form-group col-sm-12">
                            <label className="mb-3">Question</label>
                            <h6><b>{value} ? </b></h6>
                        </div>

                        <div className="form-group col-sm-12">
                            <label>Answer</label>
                            <textarea rows="3" className="form-control" name="name" value={name} onChange={(e)=>this.handleChange(e)}/>
                        </div>




                    </div>

                    <button className=" btn btn-success col-sm-3 mt-3 py-2" onClick={this.handleUpdateDetails}>Reply Now</button><ToastContainer autoClose={1500} />

                </section>


            </div>
        )
    }
}
