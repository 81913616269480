import { format } from "date-fns";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import API from "../../../../api";
import Loader from "react-loader";

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading: true,
      sortBy: "createdAt",
      direction: "ASC",
      qwery: "",
    };
  }
  async componentDidMount() {
    const querystring = `limit=100&page=1&sortBy=${this.state.sortBy}&direction=${this.state.direction}`;
    if (this.state.qwery) querystring += `&qwery=${this.state.qwery}`;
    new API()
      .getHttpClient()
      .get(`/course/recorded-masterclass?${querystring}`)
      .then((res) => {
        this.setState({ list: res.data.data, loading: false });
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }
  async handlDeleteById(row) {
    swal({
      title: "Are you sure?",
      text: `You want to delete course from the List`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        new API()
          .getHttpClient()
          .delete("/course/" + row.id)
          .then((res) => {
            toast.success("successfull Deleted");
            window.location.reload();
          })
          .catch((error) => {
            toast.error("something error!" + error);
          });
      }
    });
  }
  handleStatus = (index, value, getData) => {
    let status = !getData.isActive ? true : false;
    new API()
      .getHttpClient()
      .patch(`/course/desc/${getData.id}`, {
        isActive: status,
      })
      .then((res) => {
        toast.success("successfull Updated");
        // window.location.reload();
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  };
  handleQuery(key, value) {
    const qwery = key == "qwery" ? value : this.state.qwery || null;
    const sortBy = key == "sortBy" ? value : this.state.sortBy;
    const direction = key == "direction" ? value : this.state.direction;
    let querystring = `limit=100&page=1&sortBy=${sortBy}&direction=${direction}`;
    if (qwery) querystring += `&qwery=${qwery}`;
    this.setState({ loading: true });
    new API()
      .getHttpClient()
      .get(`/course/recorded-masterclass?${querystring}`)
      .then((res) => {
        this.setState({
          list: res.data.data,
          loading: false,
          qwery,
          sortBy,
          direction,
        });
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }
  handleIsArchive = (index, value, getData) => {
    let isArchived = !getData.isArchived ? true : false;
    new API()
      .getHttpClient()
      .patch(`/course/desc/${getData.id}`, {
        isArchived: isArchived,
      })
      .then((res) => {
        toast.success("successfull Updated");
        // window.location.reload();
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  };
  render() {
    let { list, loading } = this.state;
    return (
      <div>
        {loading ? (
          <Loader />
        ) : (
          <div className="m-courses">
            <div className="page-head">
              <div className="row">
                <div className="col-sm-6 text-head">
                  <h3>Free Recorded Masterclass</h3>
                </div>

                <div className="col-sm-6 text-right">
                  <a
                    href="/admin/course/create-course?courseType=3"
                    className="btn btn-success"
                  >
                    {" "}
                    Create New Course{" "}
                  </a>
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-sm-12 text-right">
                  <div className="row justify-content-end align-items-center">
                    <div className="col-md-3">
                      <input
                        type="text"
                        name="qwery"
                        className="form-control"
                        placeholder="search..."
                        value={this.state.qwery}
                        onChange={(e) =>
                          this.setState({ qwery: e.target.value })
                        }
                      />
                    </div>

                    <div className="col-md-1 p-0">
                      <button
                        type="button"
                        className="btn btn-success w-100"
                        onClick={(e) =>
                          this.handleQuery("qwery", this.state.qwery)
                        }
                      >
                        Search
                      </button>
                    </div>
                    <div className="col-md-1  p-0">
                      <div className="d-inline-flex w-100 justify-content-around align-items-center">
                        {this.state.direction == "DESC" && (
                          <button
                            className="actions-btn"
                            onClick={() => {
                              this.handleQuery("direction", "ASC");
                            }}
                          >
                            <i class="fas fa-sort-amount-down-alt"></i>
                          </button>
                        )}
                        {this.state.direction == "ASC" && (
                          <button
                            className="actions-btn"
                            onClick={() => {
                              this.handleQuery("direction", "DESC");
                            }}
                          >
                            <i class="fas fa-sort-amount-up-alt"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Page Head */}

            <div className="page-wrapper">
              {/* Table Starts */}
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Thumbnail</th>
                      <th scope="col">Course Name</th>
                      <th scope="col">CourseType</th>
                      <th scope="col">Published Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Archived</th>
                      <th scope="col">Edit Course</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list
                      ? list.map((row, i) => (
                          <tr key={i}>
                            <td>{++i}</td>
                            <td>
                              <img
                                src={row.thumbnail}
                                alt="thumbnail"
                                height="50px"
                              />
                            </td>
                            <td>{row.title}</td>
                            <td>
                              <p>Recorded MasterClass</p>
                            </td>
                            <td>
                              {format(new Date(row.createdAt), "dd/MM/yyyy")}
                            </td>
                            <td>
                              <button
                                type="button"
                                className={`btn btn-sm btn-secondary btn-toggle ${
                                  row.isActive ? "active" : ""
                                }`}
                                data-toggle="button"
                                aria-pressed="true"
                                autocomplete="off"
                                onClick={(e) =>
                                  this.handleStatus(i, e.target.value, row)
                                }
                              >
                                {" "}
                                <div className="handle"></div>{" "}
                              </button>
                            </td>
                            <td>
                              <button
                                type="button"
                                className={`btn btn-sm btn-secondary btn-toggle ${
                                  row.isArchived ? "active" : ""
                                }`}
                                data-toggle="button"
                                aria-pressed="true"
                                autocomplete="off"
                                onClick={(e) =>
                                  this.handleIsArchive(i, e.target.value, row)
                                }
                              >
                                <div className="handle"></div>
                              </button>
                            </td>
                            <td>
                              <Link
                                to={{
                                  pathname: `/admin/course/free-workshop/edit`,
                                  state: {
                                    id: row.id,
                                    title: row.title,
                                    description: row.description,
                                    about: row.about,
                                    sessionCount: row.sessionCount,
                                    sessionDuration: row.duration,
                                    price: row.price || 0,
                                    strikePrice: row.strikePrice || 0,
                                    slug: row.slug,
                                    thumbnail: row.thumbnail,
                                    courseType: 3,
                                    linkProductId: row.linkProductId,
                                  },
                                }}
                              >
                                <button
                                  variant="contained"
                                  className="actions-btn"
                                >
                                  <span className="typcn typcn-edit"></span>
                                </button>
                              </Link>
                              <button
                                className="actions-btn"
                                onClick={(e) => this.handlDeleteById(row)}
                              >
                                <i className="fas fa-trash-alt" />
                              </button>
                            </td>
                          </tr>
                        ))
                      : "No Data found"}
                  </tbody>
                </table>
              </div>
              {/* Table Ends */}
              <ToastContainer autoClose={1500} />
              {/* Workshop Listing Page Ends*/}
            </div>
          </div>
        )}
      </div>
    );
  }
}
