import api from '../../ApiConfig';
import { Apis } from '../../../config';
import { NotificationManager } from 'react-notifications';

const getAllWorkshop = async () => {
    try {
        let result = await api.post(Apis.GetAllWorkshop);
        if (result.error) {
            NotificationManager.error(result.error);
            return null;
        }
        return result.data;
    } catch (error) {
        return null;
    }
};

const getAllLiveSession = async () => {
    try {
        let result = await api.post(Apis.GetAllLiveSessionDetails);
        if (result.error) {
            NotificationManager.error(result.error);
            return null;
        }
        return result.data;
    } catch (error) {
        return null;
    }
};

const getAllBlogsList = async () => {
    try {
        let result = await api.post(Apis.GetAllBlogsList);
        if (result.error) {
            NotificationManager.error(result.error);
            return null;
        }
        return result.data;
    } catch (error) {
        return null;
    }
};

const getCurriculamVideoUrl = async (id) => {
    var headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
    if (localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined') {
        headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    }
    try {
        let result = await api.get(Apis.GetCurriculamVideoUrl+`${id}`,{
            headers: headers
        });
        if (result.error) {
            NotificationManager.error(result.error);
            return null;
        }
        return result.data;
    } catch (error) {
        return null;
    }
};

export default {
    getAllWorkshop,
    getAllLiveSession,
    getAllBlogsList,
    getCurriculamVideoUrl
};