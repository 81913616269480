import { format } from "date-fns";
import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../../api";

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      isLoaded: true,
    };
  }
  handleBack(e) {
    this.props.history.goBack();
  }
  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  async componentDidMount() {
    this.setState({ isLoaded: true });
    await new API()
      .getHttpClient()
      .get("/payment/getPaymentHistory")
      .then((res) => {
        this.setState({ isLoaded: false, list: res.data });
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }

  render() {
    let { list, isLoaded } = this.state;
    return (
      <div>
        {/* Page Head */}
        <div className="page-head">
          <div className="row">
            <div className="col-sm-6 text-head">
              <h3>Payments Management</h3>
            </div>

            <div className="col-sm-6 text-right"></div>
          </div>
        </div>
        {/* Page Head */}

        <div className="page-wrapper">
          <div className="sub-heads row">
            <div className="col-sm-5 text-head">
              <h5>Showing all payment details</h5>
            </div>

            <div className="col-sm-7 text-right"></div>
          </div>

          <div className="table-responsive pmt-table">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Customer </th>
                  <th>Course Purchased</th>
                  <th>Course Type</th>
                  <th>Transaction Amount</th>
                  <th>Payment Type</th>
                  <th>Payment Status</th>
                </tr>
              </thead>

              <tbody>
                {isLoaded ? (
                  <p>Loading...</p>
                ) : (
                  list.map((row, index) => (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td>{format(new Date(row.createdAt), "dd/MM/yyyy")}</td>
                      <td>{row.firstName + " " + row.lastName}</td>
                      <td>
                        <ul>
                          {row.coursePurchased.map((data) => {
                            return <li>{data}</li>;
                          })}
                        </ul>
                      </td>
                      <td>
                        <ul>
                          {row.courseType.map((data) => {
                            return (
                              <li>
                                {data === 1 ? (
                                  <p>Recorded Workshop</p>
                                ) : data === 2 ? (
                                  <p>Live Session Workshop</p>
                                ) : data === 3 ? (
                                  <p>Recorded Masterclass</p>
                                ) : (
                                  <p>Live Session Masterclass</p>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </td>
                      <td>
                        {row.paymentType === "Paytm"
                          ? "₹" + row.transactionAmount
                          : "€" + row.transactionAmount}
                      </td>
                      <td>{row.paymentType}</td>
                      <td>
                        <span className="text-success">
                          {row.paymentStatus === "PENDING" ? (
                            <span className="text-danger"> Failed </span>
                          ) : (
                            <span className="text-success">Success</span>
                          )}
                        </span>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
