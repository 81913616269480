import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { userdata: "" };
  }
  componentDidMount() {
    let firstName = localStorage.getItem("firstName");
    let lastName = localStorage.getItem("lastName");

    this.setState({ userdata: { firstName, lastName } });
  }
  render() {
    let self = this.state.userdata;
    let role = localStorage.getItem("decentrizationId");
    let username = self.firstName + " " + self.lastName;
    return (
      <div>
        <nav className="sidebar sidebar-bunker">
          <div className="sidebar-header">
            <a href="/" className="sbar-logo">
              <img src="/static/media/logo-dark.771d2b01.png" alt="logo" />
            </a>
          </div>
          <div className="profile-element d-flex align-items-center flex-shrink-0">
            <div className="profile-text">
              <h6 className="m-0">{username}</h6>
            </div>
          </div>
          <div className="sidebar-body">
            <nav className="sidebar-nav">
              <ul className="metismenu">
                <li className="nav-label">Main Menu</li>
                <li className="mm-active">
                  <a href="/admin">
                    {" "}
                    <i className="typcn typcn-th-large mr-2"></i>Dashboard
                  </a>
                </li>

                <li>
                  <a className="has-arrow material-ripple" href="#">
                    <i className="typcn typcn-th-list mr-2"></i>
                    Manage Workshops
                  </a>
                  <ul className="nav-second-level">
                    <li>
                      <Link to="/admin/course/workshop/list">
                        {" "}
                        Recorded Workshops
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/course/live-session/list?courseType=1">
                        {" "}
                        Live Sessions
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <a className="has-arrow material-ripple" href="#">
                    <i className="typcn typcn-th-list mr-2"></i>
                    Manage Masterclass
                  </a>
                  <ul className="nav-second-level">
                    <li>
                      <Link to="/admin/course/free-workshop/list">
                        {" "}
                        Recorded Masterclass
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/course/free-live-session/list">
                        Live Session Masterclass
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/admin/course/consultations/list">
                    {" "}
                    <i className="typcn icon typcn-book mr-2"></i> Manage
                    Consultations
                  </Link>
                </li>

                {/* <li
                  style={
                    role === "3" ? { display: "none" } : { display: "block" }
                  }
                >
                  <Link to="/admin/blogs/list">
                    {" "}
                    <i className="typcn icon typcn-book mr-2"></i> Blogs
                  </Link>
                </li> */}

                <li
                  style={
                    role === "3" ? { display: "none" } : { display: "block" }
                  }
                >
                  <Link to="/admin/user/list">
                    {" "}
                    <i className="typcn typcn-user mr-2"></i> Roles Management
                  </Link>
                </li>
                <li
                  style={
                    role === "3" ? { display: "none" } : { display: "block" }
                  }
                >
                  <Link to="/admin/customer/list">
                    {" "}
                    <i className="typcn typcn-group mr-2"></i> Customers
                  </Link>
                </li>

                <li
                  style={
                    role === "3" ? { display: "none" } : { display: "block" }
                  }
                >
                  <Link to="/admin/coupons/list">
                    {" "}
                    <i className="typcn typcn-star-full-outline mr-2"></i>{" "}
                    Coupons
                  </Link>
                </li>

                {/* <li>
                  <Link to="/admin/combo-offers/list">
                    {" "}
                    <i className="typcn icon typcn-user-add mr-2"></i> Combo
                    Offers
                  </Link>
                </li> */}

                <li
                  style={
                    role === "3" ? { display: "none" } : { display: "block" }
                  }
                >
                  <Link to="/admin/payments/list">
                    {" "}
                    <i className="typcn typcn-credit-card mr-2"></i> Payments
                  </Link>
                </li>

                {/* <li>
                  <Link to="/admin/course-buy">
                    {" "}
                    <i className="typcn icon typcn-user-add mr-2"></i> Course
                    Buy
                  </Link>
                </li> */}

                <li>
                  <Link to="/admin/comments/list">
                    {" "}
                    <i className="typcn icon typcn-messages mr-2"></i> Comments
                  </Link>
                </li>

                <li>
                  <Link to="/admin/newsletter">
                    {" "}
                    <i className="typcn icon typcn-clipboard mr-2"></i>{" "}
                    Newsletter
                  </Link>
                </li>
              </ul>

              <div className="text-center">
                <a
                  href="/admin/course/create-course"
                  className="btn btn-success w-75 mt-3"
                >
                  {" "}
                  Create New Course{" "}
                </a>
              </div>
            </nav>
          </div>
        </nav>
      </div>
    );
  }
}
