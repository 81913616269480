import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../api";

export default class Course_Buy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      userId: null,
      email: null,
      courseId: null,
      amount: null,
    };
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  async componentDidMount() {
    this.setState({ isLoaded: true });
    await new API()
      .getHttpClient()
      .get("/user/getAllUsers?decentralizationId=2")
      .then((res) => {
        this.setState({ isLoaded: false, list: res.data });
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });

    new API()
      .getHttpClient()
      .get("/course/getCourseNameAndIds")
      .then((res) => {
        if (res) {
          this.setState({ courseList: res.data });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }

  handleUpdateDetails = async (event) => {
    event.preventDefault();
    const { userId, amount, courseId } = this.state;
    await new API()
      .getHttpClient()
      .post("/user/directEntryOfUser", {
        userId: userId,
        amount: amount,
        courseId: courseId,
      })
      .then((res) => {
        if (res) {
          toast.success("Successfully added");
        }
      })
      .catch((error) => {
        toast.error("error !!" + error);
      });
  };

  render() {
    const { list, userId, email, courseId, amount, courseList } = this.state;
    return (
      <div>
        <div className="wrapper">
          <div className="content-wrapper">
            <div className="main-content">
              {/* Page Head */}
              <div className="page-head">
                <div className="row">
                  <div className="col-sm-6 text-head">
                    <h3>Buy Course For User</h3>
                  </div>

                  <div className="col-sm-6 text-right"></div>
                </div>
              </div>
              {/* Page Head */}

              <section className="page-wrapper">
                <div className="sub-heads row">
                  <div className="col-sm-6 text-head">
                    <h5>Please enter the coupon details below</h5>
                  </div>

                  <div className="col-sm-6 text-right"></div>
                </div>

                <div className="row">
                  <div className="col-md-3 form-group">
                    <label>Customer</label>
                    <select
                      className="form-control"
                      name="userId"
                      value={userId}
                      onChange={(e) => this.handleChange(e)}
                    >
                      <option selected>select email</option>
                      {list
                        ? list.map((row, index) => (
                            <option key={index} value={row.userId}>
                              {row.email}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>

                  {/* <div className="col-md-3 form-group">
                                        <label>Email</label>
                                        {
                                                list ?
                                                    list.map((row, index) => (
                                                        row.userId === userId?
                                                        <input className="form-control" type="text" disabled value={row.email}></input>:''
                                                    ))
                                                    : ''
                                            }
                                        
                                    </div> */}

                  <div className="col-md-3 form-group">
                    <label> Course</label>
                    <select
                      className="form-control"
                      name="courseId"
                      value={courseId}
                      onChange={(e) => this.handleChange(e)}
                    >
                      <option selected>select course</option>
                      {courseList
                        ? courseList.map((row, index) => (
                            <option key={index} value={row.id}>
                              {row.courseName}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>

                  <div className="col-md-3 form-group">
                    <label>Transaction Amount</label>
                    <input
                      className="form-control"
                      type="number"
                      placeholder="Enter Value"
                      name="amount"
                      value={amount}
                      onChange={(e) => this.handleChange(e)}
                    ></input>
                  </div>
                </div>

                <button
                  className=" btn btn-success col-sm-3 mt-3 py-2"
                  onClick={this.handleUpdateDetails}
                >
                  Buy Now
                </button>
                <ToastContainer autoClose={1500} />
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
