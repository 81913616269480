import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "./logo-dark.png";
// import API from '../../api';
import axios from "axios";

export default class Otp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      otp: "",
      passworderror: "",
      otperror: "",
      redirectToReferrer: false,
    };
    this.handlePassword = this.handlePassword.bind(this);
  }
  handleOtp(e) {
    this.setState({ otp: e.target.value });
  }
  handlePassword(p) {
    this.setState({ password: p.target.value });
  }
  validate = () => {
    let passworderror = "";
    let otperror = "";
    if (!this.state.password) {
      passworderror = "password cannot be blank";
    }
    if (!this.state.otp) {
      otperror = "otp cannot be blank";
    }
    if (passworderror || otperror) {
      this.setState({ passworderror: passworderror, otperror: otperror });
      return false;
    }
    return true;
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      let data = {
        email: this.props.location.data,
        password: this.state.password,
        otp1: this.state.otp,
      };
      axios({
        url: ``,
        data,
        method: "post",
      })
        .then((res) => {
          toast.success("successful updated!");
          window.location.href = "/auth/login";
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };
  render() {
    return (
      <div className="d-flex align-items-center justify-content-center text-center h-100vh">
        <div className="form-wrapper m-auto">
          <div className="form-container my-4">
            <div className="panel">
              <div className="panel-header text-center mb-3">
                <img src={logo} className="brand_logo" alt="Logo" />
                <h3 className="fs-24">Account Recovery</h3>
                <p className="text-muted text-center mb-0">
                  Please set your new password and enter OTP sent to your
                  registered email id.
                </p>
              </div>

              <form className="register-form">
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    value={this.props.location.data}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className={
                      this.state.passworderror
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    value={this.state.password}
                    onChange={this.handlePassword}
                    placeholder="Enter New Password"
                  />
                  {this.state.passworderror ? (
                    <div className="invalid-feedback text-left">
                      {this.state.passworderror}
                    </div>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="otp"
                    className={
                      this.state.otperror
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    value={this.state.otp}
                    onChange={(e) => this.handleOtp(e)}
                    placeholder="OTP"
                  />
                  {this.state.otperror ? (
                    <div className="invalid-feedback text-left">
                      {this.state.otperror}
                    </div>
                  ) : null}
                </div>
                <button
                  type="submit"
                  onClick={this.handleSubmit}
                  className="btn btn-success btn-block"
                >
                  submit
                </button>
                <ToastContainer autoClose={1000} />
              </form>
              <p className="text-muted text-center mt-4">
                Copyright © 2020 Hi-Tek Inc. All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
