import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../../api";

export default class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseList: [],
      firstCourseId: null,
      secondCourseId: null,
      indianPrice: null,
      otherPrice: null,
    };
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  async componentDidMount() {
    this.setState({ isLoaded: true });
    new API()
      .getHttpClient()
      .get("/course/getCourseNameAndIds")
      .then((res) => {
        if (res) {
          this.setState({ courseList: res.data });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }

  handleUpdateDetails = async (event) => {
    event.preventDefault();
    const { firstCourseId, secondCourseId, indianPrice, otherPrice } =
      this.state;
    await new API()
      .getHttpClient()
      .post("/courseCombo/createCourseCombo", {
        firstCourseId: firstCourseId,
        secondCourseId: secondCourseId,
        indianPrice: indianPrice,
        otherPrice: otherPrice,
      })
      .then((res) => {
        if (res) {
          toast.success("Successfully Created");
        }
      })
      .catch((error) => {
        toast.error("error !!" + error);
      });
  };
  render() {
    const {
      firstCourseId,
      secondCourseId,
      courseList,
      otherPrice,
      indianPrice,
    } = this.state;

    return (
      <div>
        <div className="wrapper">
          <div className="content-wrapper">
            <div className="main-content">
              {/* Page Head */}
              <div className="page-head">
                <div className="row">
                  <div className="col-sm-6 text-head">
                    <h3>Buy Course For User</h3>
                  </div>

                  <div className="col-sm-6 text-right"></div>
                </div>
              </div>
              {/* Page Head */}

              <section className="page-wrapper">
                <div className="sub-heads row">
                  <div className="col-sm-6 text-head">
                    <h5>Please enter the coupon details below</h5>
                  </div>

                  <div className="col-sm-6 text-right"></div>
                </div>

                <div className="row">
                  <div className="col-md-3 form-group">
                    <label>Course - 1</label>
                    <select
                      className="form-control"
                      name="firstCourseId"
                      value={firstCourseId}
                      onChange={(e) => this.handleChange(e)}
                    >
                      <option selected>select course</option>
                      {courseList
                        ? courseList.map((row, index) => (
                            <option key={index} value={row.id}>
                              {row.courseName}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>

                  <div className="col-md-3 form-group">
                    <label>Course - 2</label>
                    <select
                      className="form-control"
                      name="secondCourseId"
                      value={secondCourseId}
                      onChange={(e) => this.handleChange(e)}
                    >
                      <option selected>select course</option>
                      {courseList
                        ? courseList.map((row, index) => (
                            <option key={index} value={row.id}>
                              {row.courseName}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>

                  <div className="col-md-3 form-group">
                    <label>Other Combo Price</label>
                    <input
                      className="form-control"
                      type="number"
                      placeholder="Enter Value"
                      name="otherPrice"
                      value={otherPrice}
                      onChange={(e) => this.handleChange(e)}
                    ></input>
                  </div>

                  <div className="col-md-3 form-group">
                    <label>Indian Combo Price</label>
                    <input
                      className="form-control"
                      type="number"
                      placeholder="Enter Value"
                      name="indianPrice"
                      value={indianPrice}
                      onChange={(e) => this.handleChange(e)}
                    ></input>
                  </div>
                </div>

                <button
                  className=" btn btn-success col-sm-3 mt-3 py-2"
                  onClick={this.handleUpdateDetails}
                >
                  Create Combine
                </button>
                <ToastContainer autoClose={1500} />
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
