import Paper from "@material-ui/core/Paper";
import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../../api";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";

export default class Edit extends Component {
  constructor(props) {
    super(props);
    let userdata = this.props.location.state;
    this.state = {
      isLoaded: true,
      email: userdata.email,
      firstName: userdata.firstName,
      lastName: userdata.lastName,
      address: userdata.address,
      phone: userdata.phone,
      decentralization: userdata.decentrizationId,
      teacherPhotoUrl: userdata.teacherPhotoUrl,
      teacherDesc: userdata.teacherDesc,
      uploadPercentage: 0,
      role: userdata.role,
      id: userdata.id,
      isActive: userdata.isActive,
      isPublic: userdata.isPublic,
      thumbnail: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  validate = () => {
    let confirmPassworderror = "";
    let passworderror = "";
    if (!this.state.password) {
      passworderror = "password cannot be blank";
    }
    if (!this.state.confirmPassword) {
      confirmPassworderror = "password cannot be blank";
    }
    if (confirmPassworderror || passworderror) {
      this.setState({
        confirmPassworderror: confirmPassworderror,
        passworderror: passworderror,
      });
      return false;
    }
    return true;
  };
  handleBack(e) {
    this.props.history.goBack();
  }
  handleUpdate(event) {
    const teacherPhotoUrl = this.state.thumbnail
      ? { photo: this.state.thumbnail }
      : {};
    event.preventDefault();
    new API()
      .getHttpClient()
      .patch("/user", {
        userId: this.state.id,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        address: this.state.address,
        role: this.state.role,
        isActive: this.state.isActive,
        teacherDesc: this.state.teacherDesc,
        isPublic: this.state.isPublic,
        ...teacherPhotoUrl,
      })
      .then((res) => {
        if (res) {
          toast.success("Successfully Updated");
          this.setState({ isLoaded: true, userdata: res.data });
          window.location = "/admin/user/list";
        }
      })
      .catch((error) => {
        toast.error("error !!" + error);
      });
  }

  handleFile(e) {
    this.UploadFile(e.target.files[0]);
  }
  UploadFile(file) {
    new API()
      .getHttpClient()
      .get("/user/profile/upload")
      .then((res) => {
        if (res) {
          axios
            .put(`${res.data.url}`, file, {
              headers: {
                "about-type": file.type,
              },
            })
            .then((resA) => {
              toast.success("successfully uploaded!");
              this.setState({ thumbnail: res.data.filename });
            })
            .catch((error) => {
              toast.error("something error!" + error);
            });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }

  render() {
    let { uploadPercentage, isLoaded } = this.state;
    return (
      <Paper>
        <div className="page-head">
          <div className="row">
            <div className="col-sm-6 text-head">
              <h3>Update User</h3>
            </div>
          </div>
        </div>

        <section className="panel">
          <div className="sub-heads row">
            <div className="col-sm-6 text-head">
              <h5>Showing user information</h5>
            </div>

            <div className="col-sm-6 text-right">
              <button
                className=" btn btn-light"
                onClick={(e) => this.handleBack(e)}
              >
                <span className="typcn typcn-arrow-left-thick"></span>Back
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 form-group">
              <label>First Name</label>
              <input
                type="text"
                className="form-control "
                name="firstName"
                value={this.state.firstName}
                onChange={this.handleChange}
              />
            </div>

            <div className="col-md-6 form-group">
              <label>Last Name</label>
              <input
                type="text"
                className="form-control "
                name="lastName"
                value={this.state.lastName}
                onChange={this.handleChange}
              />
            </div>

            <div className="form-group col-md-6">
              <label>Email</label>
              <input
                type="text"
                className="form-control "
                id="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                disabled
              />
            </div>

            <div className="col-md-6 form-group">
              <label>Role</label>
              <select
                id="role"
                className="form-control"
                name="role"
                value={this.state.role}
                onChange={this.handleChange}
              >
                <option value="ADMIN">ADMIN</option>
                <option value="CUSTOMER">CUSTOMER</option>
                <option value="TEACHER">TEACHER</option>
              </select>
            </div>

            {this.state.role == "TEACHER" && (
              <div className="w-100">
                <div className="col-md-6 form-group">
                  <label>About Teacher</label>
                  <textarea
                    rows="4"
                    className="form-control"
                    type="text"
                    placeholder="Describe your live session"
                    name="teacherDesc"
                    value={this.state.teacherDesc}
                    onChange={this.handleChange}
                  ></textarea>
                </div>

                <div className="col-md-6 form-group">
                  <label>Teacher Image</label>
                  <input
                    type="file"
                    className="form-control"
                    name="thumbnail"
                    onChange={this.handleFile.bind(this)}
                  />
                </div>
                {uploadPercentage > 0 && (
                  <CircularProgress active value={`${uploadPercentage}%`} />
                )}
              </div>
            )}
          </div>
          <div className="mb-3 mt-1 form-check">
            <div className="row">
              <div className="col-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="isActive"
                  name="isActive"
                  checked={this.state.isActive}
                  onChange={(e) =>
                    this.handleChange({
                      ...e,
                      target: { value: e.target.checked, name: "isActive" },
                    })
                  }
                />
                <label className="form-check-label" htmlFor="isActive">
                  Status
                </label>
              </div>
              <div className="col-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="isPublic"
                  name="isPublic"
                  checked={this.state.isPublic}
                  onChange={(e) =>
                    this.handleChange({
                      ...e,
                      target: { value: e.target.checked, name: "isPublic" },
                    })
                  }
                />
                <label className="form-check-label" htmlFor="isPublic">
                  Public
                </label>
              </div>
            </div>
          </div>

          <button
            className={
              isLoaded ? "btn btn-success col-sm-3 mt-3 py-2" : "d-none"
            }
            onClick={this.handleUpdate}
          >
            Update
          </button>
          <ToastContainer autoClose={1500} />
        </section>
      </Paper>
    );
  }
}
