import React, { Component } from "react";
import Loader from "react-loader";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import API from "../../../api";

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      isLoaded: true,
    };
  }
  handleBack(e) {
    this.props.history.goBack();
  }
  handlMember(e) {
    this.setState({ member: e.target.value });
  }
  async componentDidMount() {
    this.setState({ isLoaded: true });
    await new API()
      .getHttpClient()
      .get("/user/privileged")
      .then((res) => {
        this.setState({ isLoaded: false, list: res.data.data });
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }
  async handlDeleteById(row) {
    swal({
      title: "Are you sure?",
      text: `You want to delete user ${row.email} from the List`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        new API()
          .getHttpClient()
          .delete("/user/" + row.id)
          .then((res) => {
            toast.success("successfull Deleted");
            window.location.reload();
          })
          .catch((error) => {
            toast.error("something error!" + error);
          });
      }
    });
  }
  async handleSubmitMember() {
    this.setState({ isLoaded: true });
    await new API()
      .getHttpClient()
      .get(`/user/privileged?query=${this.state.member}`)
      .then(async (res) => {
        this.setState({
          isLoaded: false,
          list: res.data.data,
        });
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }
  render() {
    let self = this.state.list;
    let isLoaded = this.state.isLoaded;
    return (
      <div>
        {!isLoaded ? (
          <div>
            <div className="page-head">
              <div className="row">
                <div className="col-sm-6 text-head">
                  <h3>Roles Management</h3>
                </div>

                <div className="col-sm-6 text-right">
                  <Link to={{ pathname: `/admin/user/create` }}>
                    <button type="button" className="btn btn-success">
                      Add New Role
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="page-wrapper">
              <div className="sub-heads row">
                <div className="col-sm-5 text-head">
                  <h5>Showing customers list</h5>
                </div>

                <div className="col-sm-7 text-right">
                  <div className="row justify-content-end">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="member"
                        className="form-control"
                        placeholder="search..."
                        value={this.state.member}
                        onChange={(e) => this.handlMember(e)}
                      />
                    </div>

                    <div className="col-md-2 p-0">
                      <button
                        type="button"
                        className="btn btn-success w-100"
                        onClick={(e) => this.handleSubmitMember(e)}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {/* <th>#</th> */}
                      <th>Name </th>
                      {/* <th>Phone No </th> */}
                      <th>Role </th>
                      <th>Email </th>
                      <th>Status </th>
                      <th>Action </th>
                    </tr>
                  </thead>
                  {this.state.isLoaded ? (
                    <p>loading</p>
                  ) : (
                    <tbody>
                      {self.map((row, i) => (
                        <tr key={i}>
                          {/* <td>{++i}</td> */}
                          <td>{row.firstName + " " + row.lastName}</td>
                          {/* <td>{row.phoneNo}</td> */}
                          <td>{row.role}</td>
                          <td>{row.email}</td>
                          <td>
                            {row.isActive ? <p>Verified</p> : <p>Pending</p>}
                          </td>

                          <td style={{ display: "-webkit-box" }}>
                            <Link
                              to={{ pathname: `/admin/user/edit`, state: row }}
                            >
                              <button
                                variant="contained"
                                className="actions-btn"
                              >
                                <span className="typcn typcn-edit"></span>
                              </button>
                            </Link>
                            <button
                              className="actions-btn"
                              onClick={(e) => this.handlDeleteById(row)}
                            >
                              <i className="fas fa-trash-alt" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}
