import React, { Component } from "react";
import Loader from "react-loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import API from "../../../api";
export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      isLoading: true,
    };
  }
  async getCourse() {
    new API()
      .getHttpClient()
      .get("/course/getCourseNameAndIds")
      .then((res) => {
        if (res) {
          this.setState({ courseList: res.data, isLoading: false });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }
  async componentDidMount() {
    this.getCourse();
    new API()
      .getHttpClient()
      .get("/course/coupons")
      .then((res) => {
        if (res) {
          this.setState({ list: res.data, isLoading: false });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }
  async handlDeleteById(row) {
    swal({
      title: "Are you sure?",
      text: `You want to delete coupon from the List`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        new API()
          .getHttpClient()
          .post("/coupon/deleteCouponById?id=" + row.id)
          .then((res) => {
            toast.success("successfull Deleted");
            window.location.reload();
          })
          .catch((error) => {
            toast.error("something error!" + error);
          });
      }
    });
  }

  render() {
    const self = this.state.list;
    const isLoading = this.state.isLoading;
    const { courseList } = this.state;
    return (
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            {/* Page Head */}
            <div className="page-head">
              <div className="row">
                <div className="col-sm-6 text-head">
                  <h3>Coupons Management</h3>
                </div>
              </div>
            </div>
            {/* Page Head */}

            <div className="page-wrapper">
              <div className="sub-heads row">
                <div className="col-sm-5 text-head">
                  <h5>Showing all coupons list</h5>
                </div>

                <div className="col-sm-7 text-right"></div>
              </div>

              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {/* <th>#</th> */}
                      <th>id</th>
                      <th>Coupon Name</th>
                      <th>Coupon Value </th>
                      <th>Currency</th>
                      <th>Type </th>
                      {/* <th>Action </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {self.length
                      ? self.map((row, i) => (
                          <tr key={i}>
                            {/* <td>{++i}</td> */}
                            <td>{row.id}</td>
                            <td>{row.name}</td>
                            <td>{row.amount}</td>
                            <td>{row.currency}</td>
                            <td>{row.type}</td>
                            {/* <td>
                          <Link
                            to={{ pathname: `/admin/coupons/edit`, state: row }}
                          >
                            <button variant="contained" className="actions-btn">
                              <span className="typcn typcn-edit"></span>
                            </button>
                          </Link>
                          <button
                            className="actions-btn"
                            onClick={(e) => this.handlDeleteById(row)}
                          >
                            <i className="fas fa-trash-alt" />
                          </button>
                        </td> */}
                          </tr>
                        ))
                      : "No Data found"}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
