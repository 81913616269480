import React, { Component } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import Login from './login';
import Forgetpassword from './forget';
import Passwordset from './setpassword'
import Logout from './logout';
import Registration from './registration-complete';
import Otp from './varify';
export default class Main extends Component {
  render() { 
    const { match } = this.props;
    return (
    	<main>
        <BrowserRouter>
          <Switch>
                <Route exact path={[`${match.path}/login`, `${match.path}`]} component={Login}/>
                <Route exact path={[`${match.path}/forget`, `${match.path}`]} component={Forgetpassword}/>
                <Route exact path={[`${match.path}/setPassword`, `${match.path}`]} component={Passwordset}/>
                <Route exact path={[`${match.path}/verify-otp`, `${match.path}`]} component={Otp}/>
                <Route exact path={[`${match.path}/registration-complete`, `${match.path}`]} component={Registration}/>
                <Route exact path={`${match.path}/logout`} component={Logout}/>
          </Switch>
        </BrowserRouter>
  		</main>
    );
  }
}