import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import axios from "axios";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import RichTextEditor from "../../../../RichTextEditor";
import API from "../../../../api";
import { GetCourseDetail } from "../../../../services";
import Loader from "react-loader";

export default class Edit extends Component {
  constructor(props) {
    super(props);
    let self = this.props.location.state;
    this.state = {
      id: self.id,
      title: self.title,
      description: self.description,
      about: self.about,
      sessionCount: self.sessionCount,
      sessionDuration: self.sessionDuration,
      price: self.price,
      courseType: self.courseType,
      slug: self.slug,
      thumbnail: self.thumbnail,
      linkProductId: self.linkProductId,
      meetingLink: self.meetingLink,
      meetingPassword: self.meetingPassword,
      metaTitle: self.metaTitle,
      metaDesc: self.metaDesc,
      dateTime: self.dateTime,
      getlist: [],
      createSubTitle: [{ subtitle: "" }],
      time: "10:00",
      modalOpen: false,
      modalLoadding: true,
      productPrice: [],
      curriculum: [],
      headingTitle: "",
      modalCercullamOpen: false,
      changedObject: {},
    };
  }
  handleModal = async () => {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
      modalLoadding: true,
    }));
    new API()
      .getHttpClient()
      .get(`/course/price/${this.state.id}`)
      .then((res) => {
        if (res) {
          this.setState({ productPrice: res.data, modalLoadding: false });
        }
      })
      .catch((error) => {
        toast.error("error !!" + error);
      });
  };
  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  formatTime(date) {
    var d = new Date(date);
    let hours = d.getHours().toString();
    let minutes = d.getMinutes().toString();

    if (hours.length < 2) hours = "0" + hours;
    if (minutes.length < 2) minutes = "0" + minutes;

    return [hours, minutes].join(":");
  }
  async getCurriculamList() {
    this.setState({ isloaded: false });
    var url = window.location.href.replace(
      /\/$/,
      ""
    ); /* remove optional end / */
    var lastSeg = url.substr(url.lastIndexOf("/") + 1);
    if (lastSeg) {
      let data = await GetCourseDetail.getCurriculamVideoUrl(lastSeg);
      if (data) {
        this.setState({ getlist: data, isloaded: true });
      }
    } else {
      NotificationManager.error("Empty data", "Input");
    }
  }
  async componentDidMount() {
    var url = window.location.href.replace(
      /\/$/,
      ""
    ); /* remove optional end / */
    var lastSeg = url.substr(url.lastIndexOf("/") + 1);
    if (lastSeg) {
      new API()
        .getHttpClient()
        .get(`/course/one/${lastSeg}`)
        .then((res) => {
          if (res) {
            this.setState({ getlist: res.data, visible: true });
          }
        })
        .catch((error) => {
          toast.error("error !!" + error);
        });
      new API()
        .getHttpClient()
        .get(`/curriculum/${this.state.id}`)
        .then((res) => {
          if (res) {
            console.log(res);
            this.setState({ curriculum: res.data });
          }
        })
        .catch((error) => {
          toast.error("error !!" + error);
        });
    } else {
      NotificationManager.error("Empty data", "Input");
    }
  }
  handleDateChange(e) {
    this.setState({
      dateTime: e.target.value,
    });
  }
  handleThumbNail = (event) => {
    this.setState({ thumbnail: event.target.files[0] });
    this.UploadFile();
  };
  UploadFile() {
    new API()
      .getHttpClient()
      .get("/course/thumbnail/upload")
      .then((res) => {
        if (res) {
          axios
            .put(`${res.data.url}`, this.state.thumbnail, {
              headers: {
                "content-type": this.state.thumbnail.type,
              },
            })
            .then((resA) => {
              toast.success("successfully uploaded!");
              this.setState({ thumbnail: res.data.filename });
              this.setState((old) => ({
                ...old,
                changedObject: {
                  ...old.changedObject,
                  thumbnail: res.data.filename,
                },
              }));
            })
            .catch((error) => {
              toast.error("something error!" + error);
            });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }
  handleSelectChange(e) {
    // this.setState({ [e.target.name]: e.target.value });
    this.setState({
      [e.target.name]: e.target.value,
      changedObject: {
        ...this.state.changedObject,
        [e.target.name]: e.target.value,
      },
    });
  }
  handleContentChange = (contentHtml) => {
    this.setState({
      about: contentHtml,
    });
  };
  //curriculam create
  handleAddSubtitle() {
    this.setState((prevState) => ({
      createSubTitle: [...prevState.createSubTitle, { subtitle: "" }],
    }));
  }
  createSubTitle() {
    return this.state.createSubTitle.map((el, i) => (
      <div className="form-group row" key={i}>
        <label className="col-sm-2 col-form-label text-right">Sub Title</label>
        <div className="col-sm-4">
          <input
            className="form-control"
            type="text"
            placeholder="Eg- any topic of that chapter"
            name="subtitle"
            value={el.subtitle || ""}
            onChange={this.handleTitleChange.bind(this, i)}
          />
        </div>
        {/* <div className="col-sm-3">
                    <input type="file" className="form-control" name="videoUrl" onChange={this.handleFileChange.bind(this, i)} />
                </div> */}
        <div className="col-sm-2">
          <select
            className="form-control"
            name="pdfselect"
            defaultValue={this.state.pdfselect}
            onChange={this.handleChangePDF.bind(this, i)}
          >
            <option selected disabled>
              Select
            </option>
            <option value={1}>PDF</option>
            <option value={2}>Video</option>
          </select>
        </div>

        <div className="col-sm-3">
          {/* IF Video */}
          {el.pdfselect === "2" ? (
            <input
              className="form-control"
              type="text"
              name="subtitleUrl"
              value={el.subtitleUrl || ""}
              onChange={this.handleTitleChange.bind(this, i)}
            />
          ) : (
            <input
              type="file"
              className="form-control "
              name="subtitleUrl"
              onChange={this.handleFileChange.bind(this, i)}
            />
          )}
          {/* IF PDF */}
        </div>
        <div className="col-sm-1">
          <button
            className="delete-btn"
            variant="contained"
            onClick={this.removeClick.bind(this, i)}
          >
            <span className="typcn typcn-delete"></span>
          </button>
          {/* {this.state.isloaded ? <span style={{ color: 'green' }}>Image Uploaded</span> : ''} */}
        </div>
      </div>
    ));
  }

  handleTitleChange(i, e) {
    const { name, value } = e.target;
    let createSubTitle = [...this.state.createSubTitle];

    createSubTitle[i] = { ...createSubTitle[i], [name]: value };
    this.setState({ createSubTitle });
  }
  handleChangePDF(i, e) {
    this.setState({ isloaded: true });
    const { name, value } = e.target;
    let createSubTitle = [...this.state.createSubTitle];

    createSubTitle[i] = { ...createSubTitle[i], [name]: value };
    this.setState({ createSubTitle });
  }
  handleFileChange(i, e) {
    let createSubTitle = [...this.state.createSubTitle];
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    new API()
      .getHttpClient()
      .post("/storage/uploadFile", formData, config)
      .then((res) => {
        if (res) {
          toast.success("Successfully Uploaded");
          createSubTitle[i] = {
            ...createSubTitle[i],
            subtitleUrl: res.data,
            isloaded: true,
          };
          this.setState({ createSubTitle });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }

  handleMasterClassVideoUrl(e) {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    new API()
      .getHttpClient()
      .post("/storage/uploadFile", formData, config)
      .then((res) => {
        if (res) {
          toast.success("Successfully Uploaded");
          this.setState({ recordedMasterClassVideoLink: res.data });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }
  removeClick(i) {
    let createSubTitle = [...this.state.createSubTitle];
    if (createSubTitle[i].subtitleUrl) {
      new API()
        .getHttpClient()
        .post(`/storage/deleteFile?fileUrl=${createSubTitle[i].subtitleUrl}`)
        .then((res) => {
          if (res) {
            createSubTitle.splice(i, 1);
            this.setState({ createSubTitle });
          }
        })
        .catch((error) => {
          toast.error("error !!" + error);
        });
    } else {
      createSubTitle.splice(i, 1);
      this.setState({ createSubTitle });
    }
  }
  //end
  handleUpdateDetails = async (event) => {
    event.preventDefault();
    const { id, thumbnail, changedObject } = this.state;
    if (typeof thumbnail != "string") {
      toast.error("Please Upload Thumbnail");
      return;
    }
    await new API()
      .getHttpClient()
      .patch(`/course/desc/${id}`, {
        ...changedObject,
      })
      .then((res) => {
        if (res) {
          toast.success("Successfully Update");
          this.setState({ getlist: res.data });

          this.props.history.replace({
            state: {
              ...this.props.location.state,
              ...changedObject,
            },
          });
        }
      })
      .catch((error) => {
        toast.error("error !!" + error);
      });
  };
  async handlDeleteAubById(subTitleId, curriculamId) {
    swal({
      title: "Are you sure?",
      text: `You want to delete sub Title from the Curriculam`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        new API()
          .getHttpClient()
          .delete("curriculum/sub/" + subTitleId)
          .then((res) => {
            toast.success("successfull Deleted");
            this.setState({
              curriculum: this.state.curriculum.map((c) => {
                if (c.id.toString() === curriculamId.toString()) {
                  c.curriculumSubs = c.curriculumSubs.filter(
                    (subCurriculum) => {
                      if (
                        subCurriculum.id.toString() === subTitleId.toString()
                      ) {
                        return false;
                      }
                      return true;
                    }
                  );
                }
                return c;
              }),
            });
          })
          .catch((error) => {
            toast.error("something error!" + error);
          });
      }
    });
  }
  handleAboutUpdate = async (event) => {
    event.preventDefault();
    const { about } = this.state;
    await new API()
      .getHttpClient()
      .patch(`/course/about/${this.state.id}`, {
        about: about,
      })
      .then((res) => {
        if (res) {
          toast.success("Successfully Update");
          // this.setState({ getlist: res.data });
          // this.props.history.goBack();
          this.props.history.replace({
            state: {
              ...this.props.location.state,
              about,
            },
          });
        }
      })
      .catch((error) => {
        toast.error("error !!" + error);
      });
  };
  handleModalCercullamOpen = () => {
    this.setState((prevState) => ({
      modalCercullamOpen: !prevState.modalCercullamOpen,
    }));
  };

  handleAddCurriculam = (event) => {
    event.preventDefault();
    new API()
      .getHttpClient()
      .post(`/curriculum`, {
        courseId: this.state.id,
        title: this.state.headingTitle,
      })
      .then((res) => {
        if (res) {
          toast.success("curriculum added successfully!");
          setTimeout(() => {
            this.props.history.push({
              pathname: `/admin/course/course-curr-edit`,
              state: {
                listOfLectures: [],
                title: this.state.headingTitle,
                courseId: this.state.id,
                curriculumId: res.data.id,
              },
            });
          }, 1000);
        }
      })
      .catch((error) => {
        toast.error("error !!" + error);
      });
  };
  async handlDeleteById(id) {
    swal({
      title: "Are you sure?",
      text: `You want to delete the Curriculam`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        new API()
          .getHttpClient()
          .delete("curriculum/" + id)
          .then((res) => {
            toast.success("successfull Deleted");
            this.setState({
              curriculum: this.state.curriculum.filter((c) => {
                if (c.id.toString() === id.toString()) {
                  return false;
                }
                return true;
              }),
            });
          })
          .catch((error) => {
            toast.error("something error!" + error);
          });
      }
    });
  }
  render() {
    let { getlist, isloaded } = this.state;
    return (
      <div>
        {/* Create New Workshop Page*/}
        {/* Page Head */}
        <div className="page-head">
          <div className="row">
            <div className="col-sm-6 text-head">
              <h3>Update Live Session</h3>
            </div>
            <div className="col-sm-6 text-right">
              <button
                type="button"
                onClick={(e) => this.handleBack(e)}
                className="btn btn-dark"
              >
                <span className="typcn typcn-arrow-left"></span> Go Back
              </button>
            </div>
          </div>
        </div>
        {/* Page Head */}

        {/* Form Fields Starts */}
        <div className="page-wrapper">
          <div className="form-group row col-sm-5 p-0">
            <label
              htmlFor="example-search-input"
              className="col-sm-4 col-form-label "
            >
              Select Course Type
            </label>
            <div className="col-sm-8">
              <select
                className="form-control"
                name="courseType"
                value={this.state.courseType}
                disabled
              >
                <option value={1}>Workshop</option>
                <option value={2}>Live Session</option>
              </select>
            </div>
          </div>

          <ul className="nav nav-pills my-4" id="pills-tab" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link show active"
                id="pills-one-tab"
                data-toggle="pill"
                href="#pills-one"
                role="tab"
                aria-controls="pills-one"
                aria-selected="true"
              >
                Details
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link show"
                id="pills-two-tab"
                data-toggle="pill"
                href="#pills-two"
                role="tab"
                aria-controls="pills-two"
                aria-selected="false"
              >
                About
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link show"
                id="pills-three-tab"
                data-toggle="pill"
                href="#pills-three"
                role="tab"
                aria-controls="pills-three"
                aria-selected="false"
              >
                Curriculum
              </a>
            </li>
          </ul>

          <div
            className="tab-content"
            id="pills-tabContent"
            style={
              this.state.courseType ? { display: "block" } : { display: "none" }
            }
          >
            {/* TAB - 1 */}
            <div
              className="tab-pane fade active show"
              id="pills-one"
              role="tabpanel"
              aria-labelledby="pills-one-tab"
            >
              <h6 className="font-weight-bold">COURSE DETAILS</h6>
              <div className="row mt-4">
                {/* Main Row Starts*/}
                <div className="col-sm-8">
                  {/* col-sm-8 Left Content Starts */}
                  {/* If Workshop is Selected */}
                  <div className="workshop-content-wrap ">
                    <div className="form-group row">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-3 col-form-label"
                      >
                        Course Title
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter you live session title"
                          name="title"
                          value={this.state.title}
                          onChange={(e) => this.handleSelectChange(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-3 col-form-label"
                      >
                        Course Description
                      </label>
                      <div className="col-sm-9">
                        <textarea
                          rows="4"
                          className="form-control"
                          type="text"
                          placeholder="Describe your live session"
                          name="description"
                          value={this.state.description}
                          onChange={(e) => this.handleSelectChange(e)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-3 col-form-label"
                      >
                        Meta Title
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Meta Title"
                          name="metaTitle"
                          value={this.state.metaTitle}
                          onChange={(e) => this.handleSelectChange(e)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-3 col-form-label"
                      >
                        Meta Description
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Meta Description"
                          name="metaDesc"
                          value={this.state.metaDesc}
                          onChange={(e) => this.handleSelectChange(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="example-number-input"
                        className="col-sm-3 col-form-label"
                      >
                        Product Link Id
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Course Product Link Id"
                          name="linkProductId"
                          value={this.state.linkProductId}
                          onChange={(e) => this.handleSelectChange(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="price"
                        className="col-sm-3 col-form-label "
                      >
                        Price
                      </label>
                      <div className="col-sm-9">
                        <Button
                          fullWidth
                          variant="outlined"
                          onClick={this.handleModal}
                        >
                          Show Price
                        </Button>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="example-url-input"
                        className="col-sm-3 col-form-label"
                      >
                        Session Count
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Eg - 10,20,30"
                          name="sessionCount"
                          value={this.state.sessionCount}
                          onChange={(e) => this.handleSelectChange(e)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="example-url-input"
                        className="col-sm-3 col-form-label "
                      >
                        Session Duration
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Eg - 60 Mins"
                          name="sessionDuration"
                          value={this.state.sessionDuration}
                          onChange={(e) => this.handleSelectChange(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="example-number-input"
                        className="col-sm-3 col-form-label"
                      >
                        Thumbnail
                      </label>
                      <div className="col-sm-9" style={{ display: "flex" }}>
                        <input
                          type="file"
                          className="form-control"
                          name="thumbnail"
                          onChange={this.handleThumbNail}
                        />
                        {/* <button
                          type="button"
                          className="btn btn-inverse-soft w-100p mb-2 mr-1"
                          onClick={this.UploadFile.bind(this)}
                        >
                          {" "}
                          Upload
                        </button> */}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="example-number-input"
                        className="col-sm-3 col-form-label"
                      >
                        Slug
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="slug-url"
                          name="slug"
                          value={this.state.slug}
                          onChange={(e) => this.handleSelectChange(e)}
                        />
                      </div>
                    </div>
                  </div>

                  {/* If Live Session is Selected */}
                  <div className="workshop-content-wrap">
                    <div className="form-group row">
                      <label
                        htmlFor="example-url-input"
                        className="col-sm-3 col-form-label "
                      >
                        Meeting Link
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="url"
                          placeholder="Enter Zoom Meeting link"
                          name="meetingLink"
                          value={this.state.meetingLink}
                          onChange={(e) => this.handleSelectChange(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-3 col-form-label"
                      >
                        Meeting Password
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Zoom Meeting password"
                          name="meetingPassword"
                          value={this.state.meetingPassword}
                          onChange={(e) => this.handleSelectChange(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="example-date-input"
                        className="col-sm-3 col-form-label "
                      >
                        Date
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="date"
                          name="dateTime"
                          value={this.formatDate(this.state.dateTime)}
                          onChange={(e) => this.handleDateChange(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="example-time-input"
                        className="col-sm-3 col-form-label "
                      >
                        Time
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="time"
                          name="time"
                          value={this.formatTime(this.state.dateTime)}
                          onChange={(e) => this.handleSelectChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  {/* col-sm-8 Left Content Ends */}
                </div>
                {/* Row */}
              </div>
              <button
                type="button"
                className="btn btn-labeled btn-success ml-2"
                onClick={this.handleUpdateDetails}
              >
                <span className="btn-label">
                  <i className="fas fa-check"></i>
                </span>
                Save Details
              </button>
              {/* Main Row Ends */}
            </div>

            {/* TAB - 2 */}
            <div
              className="tab-pane fade"
              id="pills-two"
              role="tabpanel"
              aria-labelledby="pills-two-tab"
            >
              <RichTextEditor
                content={this.state.about}
                handleContentChange={this.handleContentChange}
                placeholder="insert text here..."
              />
              <button
                type="button"
                className="btn btn-labeled btn-success m-2"
                onClick={this.handleAboutUpdate}
              >
                <span className="btn-label">
                  <i className="fas fa-check"></i>
                </span>
                Save About
              </button>
            </div>

            {/* TAB - 3 */}
            <div
              className="tab-pane fade"
              id="pills-three"
              role="tabpanel"
              aria-labelledby="pills-three-tab"
            >
              {/**************************** Course Curriculum Starts ****************************/}
              <h6 className="font-weight-bold">Upload Course Curriculum</h6>
              <div className="course-cur-wrapper mt-4">
                {/* <div className="course-cur-card">

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label" name="title">
                      Heading Title
                    </label>
                    <div className="col-sm-5">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Eg- Chapter-1, Part-1 or any title"
                        name="headingTitle"
                        value={this.state.headingTitle}
                        onChange={(e) => this.handleSelectChange(e)}
                      />
                    </div>
                  </div>
                  {this.createSubTitle()}
                  <button
                    type="button"
                    className="btn btn-success-soft w-100p ml-5"
                    onClick={this.handleAddSubtitle.bind(this)}
                  >
                    Add Sub-Title
                  </button>
                </div> */}
                <div className="d-inline-flex flex-row w-100 justify-content-center">
                  <button
                    type="button"
                    className="btn btn-success-soft w-25 p m-2"
                    onClick={this.handleModalCercullamOpen}
                  >
                    Add Curriculum
                  </button>
                </div>

                <h6 className="font-weight-bold">Course Curriculum List</h6>

                <div className="table-responsive curr-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Heading Title</th>
                        <th>Id</th>
                        {/* <th>Status</th> */}
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.curriculum.length
                        ? this.state.curriculum.map((row, index) => (
                            <tr key={index}>
                              <td>
                                <Link
                                  to={{
                                    pathname: `/admin/course/course-curr-edit`,
                                    state: {
                                      ...row,
                                      title: row.title,
                                      listOfLectures: row.curriculumSubs,
                                      curriculumId: row.id,
                                      courseId: this.state.id,
                                    },
                                    maindata: this.props.location.state,
                                  }}
                                >
                                  <button className="actions-btn">
                                    <span className="typcn typcn-edit"></span>
                                  </button>
                                </Link>
                                {row.title}
                              </td>
                              <td>{row.id}</td>
                              <td>
                                <button
                                  className="actions-btn"
                                  onClick={(e) => this.handlDeleteById(row.id)}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer autoClose={1500} />
          {/* Form Fields Ends */}
          {/* Create New Workshop Ends*/}
          <Modal
            open={this.state.modalOpen}
            onClose={this.handleModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "100vw",
                maxWidth: "600px",
                height: "330",
                maxHeight: "70vh",
                background: "#fff",
                padding: "20px",
                position: "absolute",
                boxShadow: "10px",
              }}
            >
              {this.state.modalLoadding ? (
                <Box width="100%" display="flex" flexDirection="column" p={1}>
                  <Loader />
                </Box>
              ) : (
                <Box width="100%" display="flex" flexDirection="column" p={1}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell component="thead" align="center">
                            Currency
                          </TableCell>
                          <TableCell component="thead" align="center">
                            Price
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.productPrice.map((product, index) => (
                          <TableRow key={index} scope="row">
                            <TableCell scope="column" align="center">
                              <Typography
                                align="center"
                                component="p"
                                variant="subtitle1"
                              >
                                {product.currency.toUpperCase()}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                align="center"
                                component="p"
                                variant="subtitle1"
                              >
                                {`${product.symbol}`}
                                {product.amount}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
            </div>
          </Modal>
        </div>
        <Modal
          open={this.state.modalCercullamOpen}
          onClose={this.handleModalCercullamOpen}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "100vw",
              maxWidth: "600px",
              height: "330",
              maxHeight: "70vh",
              background: "#fff",
              padding: "20px",
              position: "absolute",
              boxShadow: "10px",
            }}
          >
            <Box width="100%" display="flex" flexDirection="column" p={1}>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label" name="title">
                  Heading Title
                </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Eg- Chapter-1, Part-1 or any title"
                    name="headingTitle"
                    value={this.state.headingTitle}
                    onChange={(e) => this.handleSelectChange(e)}
                  />
                </div>
              </div>
              <div className="d-inline-flex flex-row w-100 justify-content-end">
                <button
                  type="button"
                  className="btn btn-success-soft w-25 ml-5"
                  onClick={(e) => this.handleAddCurriculam(e)}
                >
                  Add Curriculam
                </button>
              </div>
            </Box>
          </div>
        </Modal>
      </div>
    );
  }
}
