import { format } from "date-fns";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../../api";

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      isLoaded: true,
    };
  }
  handleBack(e) {
    this.props.history.goBack();
  }
  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  async componentDidMount() {
    this.setState({ isLoaded: true });
    await new API()
      .getHttpClient()
      .post("/comment/getAllComment", {
        skip: 0,
        count: 100,
      })
      .then((res) => {
        this.setState({ isLoaded: false, list: res.data });
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }

  render() {
    let { list, isLoaded } = this.state;
    console.log("List -> render -> list", list);
    return (
      <div>
        {/* Page Head */}
        <div className="page-head">
          <div className="row">
            <div className="col-sm-6 text-head">
              <h3>Comments Management</h3>
            </div>

            <div className="col-sm-6 text-right"></div>
          </div>
        </div>
        {/* Page Head */}

        <div className="page-wrapper">
          <div className="sub-heads row">
            <div className="col-sm-5 text-head">
              <h5>Showing all payment details</h5>
            </div>

            <div className="col-sm-7 text-right"></div>
          </div>

          <div className="table-responsive pmt-table">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Customer </th>
                  <th>Course Purchased</th>
                  <th>Course Type</th>
                  <th>Comment</th>
                  <th>Comment Status</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {isLoaded ? (
                  <p>Loading...</p>
                ) : (
                  list.map((row, index) => (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td>{format(new Date(row.commentDate), "dd/MM/yyyy")}</td>

                      <td>{row.userName}</td>
                      <td>{row.courseName}</td>
                      <td>
                        {row.courseType === 1
                          ? "Recorded Workshop"
                          : row.courseType === 2
                          ? "Live Session Workshop"
                          : row.courseType === 3
                          ? "Recorded Masterclass"
                          : "Live Session Masterclass"}
                      </td>

                      <td>{row.question}</td>
                      <td>
                        {row.answer ? (
                          <span className="badge badge-success">closed</span>
                        ) : (
                          <span className="badge badge-success">Open</span>
                        )}
                      </td>
                      <td>
                        <Link
                          to={{ pathname: `/admin/comments/edit`, state: row }}
                        >
                          <button variant="contained" className="actions-btn">
                            <span className="typcn typcn-edit"></span>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
