import Paper from "@material-ui/core/Paper";
import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import API from "../../../api";

export default class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      firstName: "",
      lastName: "",
      decentralization: 1,
      password: "",
      confirmPassword: "",
      passworderror: "",
      confirmPassworderror: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  validate = () => {
    let confirmPassworderror = "";
    let passworderror = "";
    if (!this.state.password) {
      passworderror = "password cannot be blank";
    }
    if (!this.state.confirmPassword) {
      confirmPassworderror = "password cannot be blank";
    }
    if (confirmPassworderror || passworderror) {
      this.setState({
        confirmPassworderror: confirmPassworderror,
        passworderror: passworderror,
      });
      return false;
    }
    return true;
  };
  handleBack(e) {
    this.props.history.goBack();
  }
  handleUpdate(event) {
    event.preventDefault();
    const { password, confirmPassword } = this.state;
    const isValid = this.validate();

    if (isValid) {
      if (password === confirmPassword) {
        new API()
          .getHttpClient()
          .post("/user/addUser", {
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            decentrizationId: this.state.decentralization,
            password: this.state.password,
          })
          .then((res) => {
            this.setState({ isLoaded: true, userdata: res.data });
            window.location = "/admin/user/list";
          })
          .catch((error) => {
            toast.error("error !!" + error);
          });
      } else {
        toast.error("Password || confirmPassword is not matching");
      }
    }
  }
  render() {
    return (
      <Paper>
        <div className="page-head">
          <div className="row">
            <div className="col-sm-6 text-head">
              <h3>Add New User</h3>
            </div>
          </div>
        </div>

        <section className="panel">
          <div className="sub-heads row">
            <div className="col-sm-6 text-head">
              <h5>Showing user information</h5>
            </div>

            <div className="col-sm-6 text-right">
              <button
                className=" btn btn-light"
                onClick={(e) => this.handleBack(e)}
              >
                <span className="typcn typcn-arrow-left-thick"></span>Back
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 form-group">
              <label>First Name</label>
              <input
                type="text"
                className="form-control "
                name="firstName"
                value={this.state.firstName}
                onChange={this.handleChange}
              />
            </div>

            <div className="col-md-6 form-group">
              <label>Last Name</label>
              <input
                type="text"
                className="form-control "
                name="lastName"
                value={this.state.lastName}
                onChange={this.handleChange}
              />
            </div>

            <div className="form-group col-md-6">
              <label>Email</label>
              <input
                type="text"
                className="form-control "
                id="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </div>

            <div className="col-md-6 form-group">
              <label>Role</label>
              <select
                id="role"
                className="form-control"
                name="decentralization"
                defaultValue={this.state.decentralization}
                onChange={this.handleChange}
              >
                <option value="1">Admin</option>
                <option value="3">Teacher</option>
              </select>
            </div>

            <div className="col-md-6 form-group">
              <label>Password</label>

              <input
                type="password"
                className={
                  this.state.passworderror
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
              />
              {this.state.passworderror ? (
                <div
                  className="invalid-feedback text-left"
                  style={{ color: "red" }}
                >
                  {this.state.passworderror}
                </div>
              ) : null}
            </div>

            <div className="col-md-6 form-group">
              <label>Re-Type</label>
              <input
                type="password"
                className={
                  this.state.confirmPassworderror
                    ? "form-control is-invalid"
                    : "form-control"
                }
                name="confirmPassword"
                value={this.state.confirmPassword}
                onChange={this.handleChange}
              />
              {this.state.confirmPassworderror ? (
                <div
                  className="invalid-feedback text-left"
                  style={{ color: "red" }}
                >
                  {this.state.confirmPassworderror}
                </div>
              ) : null}
            </div>
          </div>

          <button
            className=" btn btn-success col-sm-3 mt-3 py-2"
            onClick={this.handleUpdate}
          >
            Add New
          </button>
          <ToastContainer autoClose={1500} />
        </section>
      </Paper>
    );
  }
}
