import React, { Component } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import logo from './logo-dark.png'
import API from '../../api';

export default class Passwordset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '', confirmPassword: '' ,passworderror:'',confirmPassworderror:'',
            redirectToReferrer: false,
        }
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    validate = () => {
       let confirmPassworderror = '';
        let passworderror = '';
        if (!this.state.password) {
            passworderror = "password cannot be blank"
        }
        if (!this.state.confirmPassword) {
            confirmPassworderror = 'password cannot be blank'
        }
        if (confirmPassworderror || passworderror) {
            this.setState({ confirmPassworderror: confirmPassworderror, passworderror: passworderror })
            return false
        }
        return true
    }
    handleSubmit = event => {
        event.preventDefault();
        const { password, confirmPassword } = this.state;
        const isValid = this.validate();

        var url_string = window.location.href /* "https://hitek.enverhq.com/auth/setPassword?token=8fbd39bd-caa9-45de-9275-d03f321c42de"; */ 
        var url = new URL(url_string);
        var get_token = url.searchParams.get("token");

       if(isValid){
            if(password === confirmPassword){
                new API().getHttpClient('').post('/setPasswordAdmin?token='+get_token, {
                "password": password, 
            }).then((res) => {
                toast.success("successful updated!")
                window.location.href='/auth/registration-complete';   
            })
            .catch(error => {
                toast.error(error);
            })
            }
            else{
                toast.error("Password || confirmPassword is not matching")
            }
        }
    }
    render() {
        return (
            <div className="d-flex align-items-center justify-content-center text-center h-100vh">
                <div className="form-wrapper m-auto" >
                    <div className="form-container my-4">

                        <div className="panel">
                            <div className="panel-header text-center mb-3">
                                {/* <img src={logo} className="brand_logo" alt="Logo" /> */}
                                <h3 className="fs-24">Set Password</h3>
                                <p className="text-muted text-center mb-0">Nice to see you! Please log in with your account.</p>
                            </div>


                            <form className="register-form" >
                                {/* <div className="form-group">
                                    <input type="email" className= "form-control" value={this.props.location.data} disabled/>
                                </div> */}
                                <div className="form-group">
                                    <input type="password" className={this.state.passworderror ? "form-control is-invalid" : 'form-control'} value={this.state.password}
                                        onChange={(e)=>this.handleChange(e)} name="password" placeholder="Set New Password" />
                                    {this.state.passworderror ? <div className="invalid-feedback text-left">{this.state.passworderror}</div> : null}
                                </div>
                                <div className="form-group">
                                    <input type="password" className={this.state.confirmPassworderror ? "form-control is-invalid" : 'form-control'} value={this.state.confirmPassword}
                                        onChange={(e)=>this.handleChange(e)} name="confirmPassword" placeholder="Confirm New Password" />
                                    {this.state.confirmPassworderror ? <div className="invalid-feedback text-left">{this.state.confirmPassworderror}</div> : null}
                                </div>
                                <button type="submit" onClick={this.handleSubmit} className="btn btn-success btn-block">submit</button><ToastContainer autoClose={1500} />
                            </form>
                            <p className="text-muted text-center mt-4">Copyright © 2019 Hi-Tek Inc. All Rights Reserved</p>

                        </div>

                    </div>
                </div>
            </div>




        );
    }
}