import { format } from "date-fns";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import API from "../../../api";
import { GetCourseDetail } from "../../../services";

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
  }
  async componentDidMount() {
    let list = await GetCourseDetail.getAllBlogsList();
    if (list) {
      this.setState({ list: list });
    }
    // new API().getHttpClient().post('/blog/getAllBlog').then((res) => {
    //     if (res) {
    //         this.setState({ list: res.data });
    //     }
    // })
    //     .catch(error => {
    //         toast.error("something error!" + error)
    //     })
  }
  async handlDeleteById(row) {
    swal({
      title: "Are you sure?",
      text: `You want to delete blogs from the List`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        new API()
          .getHttpClient()
          .post("/blog/deleteBlogById?id=" + row.id)
          .then((res) => {
            toast.success("successfull Deleted");
            window.location.reload();
          })
          .catch((error) => {
            toast.error("something error!" + error);
          });
      }
    });
  }
  render() {
    let { list } = this.state;
    console.log(list);
    return (
      <div>
        {/* Page Head */}
        <div className="page-head">
          <div className="row">
            <div className="col-sm-6 text-head">
              <h3>Blogs</h3>
            </div>

            <div className="col-sm-6 text-right">
              <a href="/admin/blogs/create" className="btn btn-success">
                {" "}
                Create New Blog{" "}
              </a>
            </div>
          </div>
        </div>
        {/* Page Head */}

        <div className="page-wrapper">
          <div className="sub-heads row">
            <div className="col-sm-5 text-head">
              <h5>Showing all coupons list</h5>
            </div>

            <div className="col-sm-7 text-right"></div>
          </div>

          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Thumbnail</th>
                  <th scope="col">Blog Name</th>
                  <th scope="col">Published Date</th>
                  <th scope="col">Edit Course</th>
                </tr>
              </thead>
              <tbody>
                {list
                  ? list.map((row, index) => (
                      <tr key={index}>
                        <td>{++index}</td>
                        <td>
                          <img
                            src={row.imageUrl}
                            alt="thumbnail"
                            height="50px"
                          />
                        </td>
                        <td>{row.title}</td>
                        <td>{format(new Date(row.blogDate), "dd/MM/yyyy")}</td>
                        <td>
                          <Link
                            to={{ pathname: `/admin/blogs/edit`, state: row }}
                          >
                            <button variant="contained" className="actions-btn">
                              <span className="typcn typcn-edit"></span>
                            </button>
                          </Link>
                          <button
                            className="actions-btn"
                            onClick={(e) => this.handlDeleteById(row)}
                          >
                            <i className="fas fa-trash-alt" />
                          </button>
                        </td>
                      </tr>
                    ))
                  : "No Data found"}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
