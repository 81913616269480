import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Create_Course from "./create-course";
import Workshop from "./workshop";
import Livesession from "./live-session";
import Free_Workshop from "./free-workshop";
import Free_Livesession from "./free-live-session";
import Course_Curr_Edit from "./course-curr-edit";
import Consultations from "./consultations";

export default class Courses extends Component {
  render() {
    const { match } = this.props;
    return (
      <main>
        <div className="wrapper">
          <div className="content-wrapper">
            <div className="main-content">
              <Switch>
                <Route
                  path={`${match.path}/create-course`}
                  component={Create_Course}
                />
                <Route path={`${match.path}/workshop`} component={Workshop} />
                <Route
                  path={`${match.path}/live-session`}
                  component={Livesession}
                />
                <Route
                  path={`${match.path}/free-workshop`}
                  component={Free_Workshop}
                />
                <Route
                  path={`${match.path}/free-live-session`}
                  component={Free_Livesession}
                />
                <Route
                  path={`${match.path}/course-curr-edit`}
                  component={Course_Curr_Edit}
                />
                <Route
                  path={`${match.path}/consultations`}
                  component={Consultations}
                />
              </Switch>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
