import React, { Component } from 'react'
import RichTextEditor from '../../../../RichTextEditor';
export default class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: ``
        }
    }
    handleContentChange = contentHtml => {
        this.setState({
          content: contentHtml
        });
        this.props.onSelectAbout(contentHtml);
      };
    render() {
        return (
            <div>
                <RichTextEditor
                    content={this.state.content}
                    handleContentChange={this.handleContentChange}
                    placeholder="insert text here..."
                />
            </div>
        )
    }
}
