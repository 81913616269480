import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select";
import axios from "axios";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import API from "../../../api";
import About from "./about";

export default class Create_Course extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getlist: [],
      teacherList: [],
      teacherId: "",
      isloaded: false,
      pdfselect: "1",
      courseType:
        new URLSearchParams(this.props.location.search).get("courseType") || "",
      categoryId: 1,
      courseTitle: "",
      sessionCount: "",
      sessionDuration: "",
      description: "",
      price: "",
      strikePrice: "",
      indianPrice: "",
      indianStrikePrice: "",
      slug: "",
      thumbnailUrl: "",
      recordedMasterClassVideoLink: "",
      meetingLink: "",
      meetingPassword: "",
      about: "",
      publishedDate: "",
      time: "10:00",
      subtitleUrl: "",
      createSubTitle: [{ subtitle: "" }],
      visible: false,
      courseTitleError: "",
      descriptionErrror: "",
      modalAddCategory: false,
      categoryName: "",
      categoryType: "",
      typeCategory: [],
      metaTitle: "",
      metaDescription: "",
      productLinkId: "",
      dateTime: "",
      thumbnail: "",
    };
  }
  handleDateChange(e) {
    this.setState({
      dateTime: e.target.value,
    });
  }
  handleTimeChange(e) {
    this.setState({
      dateTime: new Date(
        new Date(this.state.dateTime).setHours(
          e.target.value?.split(":")?.[0],
          e.target.value?.split(":")?.[1]
        )
      ),
    });
  }
  validate() {
    let courseTitleError = "",
      descriptionErrror = "";
    if (!this.state.courseTitle) {
      courseTitleError = "CourseTitle cannot be blank";
    }
    if (!this.state.description) {
      descriptionErrror = "Description cannot be blank";
    }
    if (courseTitleError || descriptionErrror) {
      this.setState({
        courseTitleError: courseTitleError,
        descriptionErrror: descriptionErrror,
      });
      return false;
    }
    return true;
  }
  handleSelectChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onChangeAbout(value) {
    this.setState({ about: value });
  }
  async componentDidMount() {
    await new API()
      .getHttpClient()
      .get("/user/teachers")
      .then((res) => {
        this.setState({ teacherList: res.data });
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
    await this.handleFetchFields();
  }
  // Create SubTitle Block
  handleAddSubtitle() {
    this.setState((prevState) => ({
      createSubTitle: [...prevState.createSubTitle, { subtitle: "" }],
    }));
  }
  createSubTitle() {
    return this.state.createSubTitle.map((el, i) => (
      <div className="form-group row" key={i}>
        <label className="col-sm-2 col-form-label text-right">Sub Title</label>
        <div className="col-sm-4">
          <input
            className="form-control"
            type="text"
            placeholder="Eg- any topic of that chapter"
            name="subtitle"
            value={el.subtitle || ""}
            onChange={this.handleTitleChange.bind(this, i)}
          />
        </div>
        <div className="col-sm-2">
          <select
            className="form-control"
            name="pdfselect"
            defaultValue={this.state.pdfselect}
            onChange={this.handleChangePDF.bind(this, i)}
          >
            <option selected disabled>
              Select
            </option>
            <option value={1}>PDF</option>
            <option value={2}>Video</option>
          </select>
        </div>

        <div className="col-sm-3">
          {/* IF Video */}
          {el.pdfselect === "2" ? (
            <input
              className="form-control"
              type="text"
              name="videoUrl"
              value={el.videoUrl || ""}
              onChange={this.handleTitleChange.bind(this, i)}
            />
          ) : (
            <input
              type="file"
              className="form-control "
              name="videoUrl"
              onChange={this.handleFileChange.bind(this, i)}
            />
          )}
          {/* IF PDF */}
        </div>
        <div className="col-sm-1">
          <button
            className="delete-btn"
            variant="contained"
            onClick={this.removeClick.bind(this, i)}
          >
            <span className="typcn typcn-delete"></span>
          </button>
          {/* {this.state.isloaded && <i className="fa fa-refresh fa-spin"></i>}
               {this.state.isloaded && <span> File Uploading </span>}
               {!this.state.isloaded && <span>  </span>} */}
        </div>
      </div>
    ));
  }

  handleTitleChange(i, e) {
    this.setState({ isloaded: true });
    const { name, value } = e.target;
    let createSubTitle = [...this.state.createSubTitle];

    createSubTitle[i] = { ...createSubTitle[i], [name]: value };
    this.setState({ createSubTitle });
  }
  handleChangePDF(i, e) {
    this.setState({ isloaded: true });
    const { name, value } = e.target;
    let createSubTitle = [...this.state.createSubTitle];

    createSubTitle[i] = { ...createSubTitle[i], [name]: value };
    this.setState({ createSubTitle });
  }
  handleFileChange(i, e) {
    this.setState({ isloaded: true });
    let createSubTitle = [...this.state.createSubTitle];
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    setTimeout(async () => {
      this.setState({ isloaded: false });
      new API()
        .getHttpClient()
        .post("/storage/uploadFile", formData, config)
        .then((res) => {
          if (res) {
            createSubTitle[i] = { ...createSubTitle[i], subtitleUrl: res.data };
            this.setState({ createSubTitle });
          }
        })
        .catch((error) => {
          toast.error("something error!" + error);
        });
    }, 3000);
  }

  handleThumbNail = (event) => {
    this.setState({ thumbnail: event.target.files[0] });
    this.UploadFile();
  };
  UploadFile() {
    new API()
      .getHttpClient()
      .get("/course/thumbnail/upload")
      .then((res) => {
        if (res) {
          axios
            .put(`${res.data.url}`, this.state.thumbnail, {
              headers: {
                "about-type": this.state.thumbnail.type,
              },
            })
            .then((resA) => {
              toast.success("successfully uploaded!");
              this.setState({ thumbnail: res.data.filename });
              this.setState((old) => ({
                ...old,
                changedObject: {
                  ...old.changedObject,
                  thumbnail: res.data.filename,
                },
              }));
            })
            .catch((error) => {
              toast.error("something error!" + error);
            });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }

  handleMasterClassVideoUrl(e) {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    new API()
      .getHttpClient()
      .post("/storage/uploadFile", formData, config)
      .then((res) => {
        if (res) {
          toast.success("Successfully Uploaded");
          this.setState({ recordedMasterClassVideoLink: res.data });
        }
      })
      .catch((error) => {
        toast.error("something error!" + error);
      });
  }
  removeClick(i) {
    let createSubTitle = [...this.state.createSubTitle];
    if (createSubTitle[i].subtitleUrl) {
      new API()
        .getHttpClient()
        .post(`/storage/deleteFile?fileUrl=${createSubTitle[i].subtitleUrl}`)
        .then((res) => {
          if (res) {
            createSubTitle.splice(i, 1);
            this.setState({ createSubTitle });
          }
        })
        .catch((error) => {
          toast.error("error !!" + error);
        });
    } else {
      createSubTitle.splice(i, 1);
      this.setState({ createSubTitle });
    }
  }
  //End SubTitle

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  formatTime(date) {
    var d = new Date(date);
    let hours = d.getHours().toString();
    let minutes = d.getMinutes().toString();

    if (hours.length < 2) hours = "0" + hours;
    if (minutes.length < 2) minutes = "0" + minutes;

    return [hours, minutes].join(":");
  }

  handleSaveDetails = (event) => {
    event.preventDefault();
    const isValid = this.validate();

    const {
      courseTitle,
      courseType,
      categoryId,
      description,
      sessionCount,
      sessionDuration,
      meetingLink,
      meetingPassword,
      slug,
      about,
      thumbnailUrl,
      createSubTitle,
      teacherId,
      headingTitle,
      recordedMasterClassVideoLink,
      metaTitle,
      metaDescription,
      productLinkId,
      dateTime,
      thumbnail,
    } = this.state;
    let lectureEmbeddedCourse = {
      headingTitle: headingTitle,
      listOfLectures: createSubTitle,
    };
    if (isValid) {
      if (courseType === "1") {
        new API()
          .getHttpClient()
          .post("/course/recorded-workshop", {
            title: courseTitle,
            description: description,
            metaTitle,
            metaDescription,
            sessionCount: sessionCount,
            sessionDuration: sessionDuration,
            teacher: teacherId,
            slug: slug,
            categoryId: categoryId,
            productLinkId,
            thumbnail,
          })
          .then((res) => {
            if (res) {
              toast.success("Successfully Added New Course");
              this.props.history.push({
                pathname: `/admin/course/workshop/edit/${res.data.id}`,
                state: {
                  ...this.state,
                  title: this.state.courseTitle,
                  linkProductId: productLinkId,
                },
              });
              // window.location.href = `/admin/course/workshop/edit/${res.data.id}`;
            }
          })
          .catch((error) => {
            toast.error("error !!" + error);
          });
      }
      if (courseType === "2") {
        new API()
          .getHttpClient()
          .post("/course/live-workshop", {
            title: courseTitle,
            description: description,
            metaTitle,
            metaDescription,
            sessionCount: sessionCount,
            sessionDuration: sessionDuration,
            thumbnail,
            teacher: teacherId,
            slug: slug,
            categoryId: categoryId,
            meetingLink,
            meetingPassword,
            dateTime,
            productLinkId,
          })
          .then((res) => {
            if (res) {
              toast.success("Successfully Added New Course");
              this.props.history.push({
                pathname: `/admin/course/live-session/edit/${res.data.id}`,
                state: {
                  ...this.state,
                  title: this.state.courseTitle,
                  linkProductId: productLinkId,
                },
              });
            }
          })
          .catch((error) => {
            toast.error("error !!" + error);
          });
      }
      if (courseType === "3") {
        new API()
          .getHttpClient()
          .post("/course/recorded-masterclass", {
            title: courseTitle,
            description: description,
            metaTitle,
            metaDescription,
            sessionCount: sessionCount,
            sessionDuration: sessionDuration,
            teacher: teacherId,
            slug: slug,
            categoryId: categoryId,

            productLinkId,
            thumbnail,
          })
          .then((res) => {
            if (res) {
              toast.success("Successfully Added New Course");
              this.props.history.push({
                pathname: `/admin/course/free-workshop/edit/${res.data.id}`,
                state: {
                  ...this.state,
                  title: this.state.courseTitle,
                  linkProductId: productLinkId,
                },
              });
            }
          })
          .catch((error) => {
            toast.error("error !!" + error);
          });
      }
      if (courseType === "4") {
        new API()
          .getHttpClient()
          .post("/course/live-masterclass", {
            title: courseTitle,
            description: description,
            metaTitle,
            metaDescription,
            sessionCount: sessionCount,
            sessionDuration: sessionDuration,
            thumbnail,
            teacher: teacherId,
            slug: slug,
            categoryId: categoryId,
            meetingLink,
            meetingPassword,
            dateTime,
            productLinkId,
          })
          .then((res) => {
            if (res) {
              toast.success("Successfully Added New Course");
              this.props.history.push({
                pathname: `/course/free-live-session/edit`,
                state: {
                  ...this.state,
                  title: this.state.courseTitle,
                  linkProductId: productLinkId,
                },
              });
            }
          })
          .catch((error) => {
            toast.error("error !!" + error);
          });
      }
      if (courseType === "5") {
        new API()
          .getHttpClient()
          .post("/course/consultation", {
            title: courseTitle,
            description: description,
            metaTitle,
            metaDescription,
            sessionDuration: sessionDuration,
            slug: slug,
            categoryId: categoryId,
            productLinkId,
            thumbnail,
          })
          .then((res) => {
            if (res) {
              toast.success("Successfully Added New Course");
              this.props.history.push({
                pathname: `/admin/course/consultation/edit/${res.data.id}`,
                state: {
                  ...this.state,
                  title: this.state.courseTitle,
                  linkProductId: productLinkId,
                },
              });
            }
          })
          .catch((error) => {
            toast.error("error !!" + error);
          });
      }
    }
    // if (isValid) {
    //   if (
    //     lectureEmbeddedCourse.headingTitle &&
    //     lectureEmbeddedCourse.listOfLectures.length
    //   ) {
    //     if (courseType === "3") {
    //       new API()
    //         .getHttpClient()
    //         .post("/course/createCourse", {
    //           courseTitle: courseTitle,
    //           courseType: courseType,
    //           description: description,
    //           sessionCount: sessionCount,
    //           sessionDuration: sessionDuration,
    //           slug: slug,
    //           thumbnailUrl: thumbnailUrl,
    //           recordedMasterClassVideoLink: recordedMasterClassVideoLink,
    //           about: about,
    //           teacherId: teacherId,
    //         })
    //         .then((res) => {
    //           if (res) {
    //             toast.success("Successfully Added New Course");
    //             this.setState({ getlist: res.data, visible: true });
    //             this.props.history.goBack();
    //           }
    //         })
    //         .catch((error) => {
    //           toast.error("error !!" + error);
    //         });
    //     } else {
    //       new API()
    //         .getHttpClient()
    //         .post("/course/createCourse", {
    //           courseType: courseType,
    //           categoryId: categoryId,
    //           courseTitle: courseTitle,
    //           description: description,
    //           sessionCount: sessionCount,
    //           sessionDuration: sessionDuration,
    //           teacherId: teacherId,
    //           slug: slug,
    //           price: price,
    //           strikePrice: strikePrice,
    //           indianPrice: indianPrice,
    //           indianStrikePrice: indianStrikePrice,
    //           about: about,
    //           thumbnailUrl: thumbnailUrl,
    //           lectureEmbeddedCourse: lectureEmbeddedCourse,
    //           time: time,
    //           date: publishedDate,
    //           meetingLink: meetingLink,
    //           meetingPassword: meetingPassword,
    //         })
    //         .then((res) => {
    //           if (res) {
    //             toast.success("Successfully Added New Course");
    //             this.setState({ getlist: res.data, visible: true });
    //             this.props.history.goBack();
    //           }
    //         })
    //         .catch((error) => {
    //           toast.error("error !!" + error);
    //         });
    //     }
    //   } else {
    //     if (courseType === "3") {
    //       new API()
    //         .getHttpClient()
    //         .post("/course/createCourse", {
    //           courseTitle: courseTitle,
    //           courseType: courseType,
    //           description: description,
    //           slug: slug,
    //           thumbnailUrl: thumbnailUrl,
    //           recordedMasterClassVideoLink: recordedMasterClassVideoLink,
    //           about: about,
    //           teacherId: teacherId,
    //         })
    //         .then((res) => {
    //           if (res) {
    //             toast.success("Successfully Added New Course");
    //             this.setState({ getlist: res.data, visible: true });
    //             this.props.history.goBack();
    //           }
    //         })
    //         .catch((error) => {
    //           toast.error("error !!" + error);
    //         });
    //     } else {
    //       new API()
    //         .getHttpClient()
    //         .post("/course/createCourse", {
    //           courseType: courseType,
    //           categoryId: categoryId,
    //           courseTitle: courseTitle,
    //           description: description,
    //           sessionCount: sessionCount,
    //           sessionDuration: sessionDuration,
    //           teacherId: teacherId,
    //           slug: slug,
    //           price: price,
    //           strikePrice: strikePrice,
    //           indianPrice: indianPrice,
    //           indianStrikePrice: indianStrikePrice,
    //           about: about,
    //           thumbnailUrl: thumbnailUrl,
    //           time: time,
    //           date: publishedDate,
    //           meetingLink: meetingLink,
    //           meetingPassword: meetingPassword,
    //         })
    //         .then((res) => {
    //           if (res) {
    //             toast.success("Successfully Added New Course");
    //             this.setState({ getlist: res.data, visible: true });
    //             this.props.history.goBack();
    //           }
    //         })
    //         .catch((error) => {
    //           toast.error("error !!" + error);
    //         });
    //     }
    //   }
    // }
  };
  handleUpdateCourse = async (event) => {
    event.preventDefault();
    let { getlist } = this.state;
    const {
      courseTitle,
      courseType,
      categoryId,
      sessionCount,
      sessionDuration,
      description,
      price,
      strikePrice,
      slug,
      about,
      thumbnailUrl,
      thumbnail,
      createSubTitle,
      headingTitle,
    } = this.state;
    let lectureEmbeddedCourse = {
      headingTitle: headingTitle,
      listOfLectures: createSubTitle,
    };
    await new API()
      .getHttpClient()
      .post("/course/updateCourse", {
        id: getlist.id,
        courseType: courseType,
        categoryId: categoryId,
        courseTitle: courseTitle,
        sessionCount: sessionCount,
        sessionDuration: sessionDuration,
        description: description,
        slug: slug,
        price: price,
        strikePrice: strikePrice,
        about: about,
        lectureEmbeddedCourse: lectureEmbeddedCourse,
      })
      .then((res) => {
        if (res) {
          toast.success("Successfully Update");
          this.setState({ getlist: res.data });
          this.props.history.goBack();
        }
      })
      .catch((error) => {
        toast.error("error !!" + error);
      });
  };
  async handlDeleteById(id) {
    swal({
      title: "Are you sure?",
      text: `You want to delete course from the Curriculam`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        new API()
          .getHttpClient()
          .post("/course/deleteLectures?id=" + id)
          .then((res) => {
            toast.success("successfull Deleted");
            window.location.reload();
          })
          .catch((error) => {
            toast.error("something error!" + error);
          });
      }
    });
  }
  handleModalAddCategory = () => {
    this.setState({ modalAddCategory: !this.state.modalAddCategory });
  };

  handleOnAddCategorySubmit = async (e) => {
    e.preventDefault();
    await new API()
      .getHttpClient()
      .post("category", {
        name: this.state.categoryName,
        type: this.state.categoryType,
      })
      .then((res) => {
        if (res) {
          this.handleModalAddCategory();
          toast.success("Successfully Added!");
          window.location.reload();
        }
      })
      .catch((error) => {
        toast.error(
          "error !!" + error.data
            ? error.response.data
              ? error.response.data.message
                ? error.response.data.message
                : error.message
                ? error.message
                : ""
              : ""
            : ""
        );
      });
  };

  handleFetchFields = async () => {
    await new API()
      .getHttpClient()
      .get("category")
      .then((res) => {
        let a = {};
        if (res) {
          res.data.forEach(({ type, name, id }) => {
            if (!a[type]) a[type] = [];
            a[type].push({ name, id });
          });
          this.setState({ typeCategory: a });
        }
      })
      .catch((error) => {
        toast.error("error !!" + error);
      });
  };
  render() {
    let { getlist, visible, teacherList } = this.state;
    let option = teacherList.map((data, index) => (
      <option key={index} value={data.id}>
        {data.firstName}
      </option>
    ));

    return (
      <div>
        {/* Create New Workshop Page*/}
        {/* Page Head */}
        <div>
          <div className="page-head">
            <div className="row">
              <div className="col-sm-6 text-head">
                <h3>Create New Course</h3>
              </div>
              <div className="col-sm-6 text-right">
                <button
                  type="button"
                  onClick={(e) => this.handleBack(e)}
                  className="btn btn-dark"
                >
                  <span className="typcn typcn-arrow-left"></span> Go Back
                </button>
              </div>
            </div>
          </div>
          {/* Page Head */}
          {/* Form Fields Starts */}
          <div className="page-wrapper">
            <div className="form-group row col-sm-5 p-0">
              <label
                htmlFor="example-search-input"
                className="col-sm-4 col-form-label "
              >
                Select Course Type
              </label>
              <div className="col-sm-8">
                <select
                  className="form-control"
                  name="courseType"
                  value={this.state.courseType}
                  onChange={(e) => this.handleSelectChange(e)}
                >
                  <option selected>Select</option>
                  <option value={1}>Recorded Workshop</option>
                  <option value={2}>Live Session Workshop</option>
                  <option value={3}>Recorded Masterclass</option>
                  <option value={4}>Live Session Masterclass</option>
                  <option value={5}>Consultation</option>
                </select>
              </div>
            </div>

            <div className="form-group row col-sm-5 p-0">
              <label
                htmlFor="example-search-input"
                className="col-sm-4 col-form-label "
              >
                Select Category
              </label>
              <div className="col-sm-8">
                <div className="d-inline-flex align-items-center">
                  <select
                    className="form-control"
                    name="categoryId"
                    value={this.state.categoryId}
                    onChange={(e) => this.handleSelectChange(e)}
                  >
                    <option selected disabled>
                      Select
                    </option>
                    {/* {Object.entries(this?.state?.typeCategory)?.map(
                      ([key, entrie]) => (
                        <>
                          <option disabled>------${key}------</option>;
                        </>
                      )
                    )} */}
                    {Object.entries(this.state.typeCategory).map(([e1, e2]) => (
                      <React.Fragment key={e1}>
                        <option disabled>------{e1}------</option>
                        {e2.map((e, i) => (
                          <option value={e.id} key={i}>
                            {e.name}
                          </option>
                        ))}
                      </React.Fragment>
                    ))}
                  </select>
                  <img
                    height="25"
                    src="/assets/icons/add_circle_black.svg"
                    alt="Add Category"
                    className="mx-2 pe-auto"
                    style={{ cursor: "pointer" }}
                    onClick={this.handleModalAddCategory}
                  />
                </div>
              </div>
            </div>
            <Modal
              open={this.state.modalAddCategory}
              onClose={() => this.handleModalAddCategory(false)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "100vw",
                  maxWidth: "450px",
                  height: "330",
                  maxHeight: "70vh",
                  background: "#fff",
                  padding: "20px",
                  position: "absolute",
                  boxShadow: "10px",
                }}
              >
                <Box width="100%" display="flex" flexDirection="column" p={1}>
                  <form onSubmit={this.handleOnAddCategorySubmit}>
                    <Box marginY={1}>
                      <Typography variant="h5">Add Category</Typography>
                    </Box>
                    <Box
                      marginY={1}
                      width="100%"
                      display="flex"
                      flexDirection="column"
                    >
                      <FormControl variant="outlined" fullWidth margin="10">
                        <InputLabel id="demo-simple-select-outlined-label">
                          Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          name="categoryType"
                          onChange={(e) =>
                            this.setState({ categoryType: e.target.value })
                          }
                          label="Age"
                          fullWidth
                          required
                          value={this.state.categoryType}
                        >
                          <MenuItem value="consultation">Consultation</MenuItem>
                          <MenuItem value="program">Program</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box
                      marginY={1}
                      width="100%"
                      display="flex"
                      flexDirection="column"
                    >
                      <TextField
                        id="outlined-basic"
                        label="Category Name"
                        variant="outlined"
                        fullWidth
                        required
                        margin="4"
                        name="categoryName"
                        value={this.state.categoryName}
                        onChange={(e) =>
                          this.setState({ categoryName: e.target.value })
                        }
                      />
                    </Box>
                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="flex-end"
                      marginY={1}
                    >
                      <Button type="submit" variant="outlined" color="primary">
                        Add
                      </Button>
                    </Box>
                  </form>
                </Box>
              </div>
            </Modal>
            {/* <ul className="nav nav-pills my-4" id="pills-tab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link show active"
                  id="pills-one-tab"
                  data-toggle="pill"
                  href="#pills-one"
                  role="tab"
                  aria-controls="pills-one"
                  aria-selected="true"
                >
                  Details
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link show"
                  id="pills-two-tab"
                  data-toggle="pill"
                  href="#pills-two"
                  role="tab"
                  aria-controls="pills-two"
                  aria-selected="false"
                >
                  About
                </a>
              </li>

              <li
                className="nav-item"
                style={
                  this.state.courseType !== "3" && this.state.courseType !== "5"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <a
                  className="nav-link show"
                  id="pills-three-tab"
                  data-toggle="pill"
                  href="#pills-three"
                  role="tab"
                  aria-controls="pills-three"
                  aria-selected="false"
                >
                  Curriculum
                </a>
              </li>
            </ul> */}

            <div
              className="tab-content"
              id="pills-tabContent"
              style={
                this.state.courseType
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              {/* TAB - 1 */}
              <div
                className="tab-pane fade active show"
                id="pills-one"
                role="tabpanel"
                aria-labelledby="pills-one-tab"
              >
                <h6 className="font-weight-bold">Course Details</h6>
                <div className="row mt-4">
                  {/* Main Row Starts*/}
                  <div className="col-sm-8">
                    {/* col-sm-8 Left Content Starts */}
                    {/* If Workshop is Selected */}
                    <div className="workshop-content-wrap ">
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-3 col-form-label"
                        >
                          Course Title
                        </label>
                        <div className="col-sm-9">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter you live session title"
                            name="courseTitle"
                            value={this.state.courseTitle}
                            onChange={(e) => this.handleSelectChange(e)}
                          />
                          {this.state.courseTitleError ? (
                            <span className="text-danger ">
                              {this.state.courseTitleError}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-3 col-form-label"
                        >
                          Course Description
                        </label>
                        <div className="col-sm-9">
                          <textarea
                            rows="4"
                            className="form-control"
                            type="text"
                            placeholder="Describe your live session"
                            name="description"
                            value={this.state.description}
                            onChange={(e) => this.handleSelectChange(e)}
                          />
                          {this.state.descriptionErrror ? (
                            <span className="text-danger ">
                              {this.state.descriptionErrror}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-3 col-form-label"
                        >
                          Meta Title
                        </label>
                        <div className="col-sm-9">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Meta Title"
                            name="metaTitle"
                            value={this.state.metaTitle}
                            onChange={(e) => this.handleSelectChange(e)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-3 col-form-label"
                        >
                          Meta Description
                        </label>
                        <div className="col-sm-9">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Meta Description"
                            name="metaDescription"
                            value={this.state.metaDescription}
                            onChange={(e) => this.handleSelectChange(e)}
                          />
                        </div>
                      </div>

                      <div className={"workshop-content-wrap"}>
                        <div className="form-group row">
                          <label
                            htmlFor="example-number-input"
                            className="col-sm-3 col-form-label"
                          >
                            Product Link Id
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Enter Course Product Link Id"
                              name="productLinkId"
                              value={this.state.productLinkId}
                              onChange={(e) => this.handleSelectChange(e)}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        style={
                          this.state.courseType !== "5"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <div className="form-group row">
                          <label
                            htmlFor="example-url-input"
                            className="col-sm-3 col-form-label"
                          >
                            Session Count
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Eg - 10,20,30"
                              name="sessionCount"
                              value={this.state.sessionCount}
                              onChange={(e) => this.handleSelectChange(e)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="example-url-input"
                          className="col-sm-3 col-form-label "
                        >
                          Session Duration
                        </label>
                        <div className="col-sm-9">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Eg - 60 Mins"
                            name="sessionDuration"
                            value={this.state.sessionDuration}
                            onChange={(e) => this.handleSelectChange(e)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="example-number-input"
                          className="col-sm-3 col-form-label"
                        >
                          Image Thumbnail
                        </label>
                        <div className="col-sm-9" style={{ display: "flex" }}>
                          <input
                            type="file"
                            className="form-control"
                            name="thumbnailUrl"
                            onChange={this.handleThumbNail}
                          />
                          {this.state.thumbnailUrl ? (
                            <span className="text-success">file Uploaded</span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div
                        style={
                          this.state.courseType !== "5"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <div className="form-group row">
                          <label
                            htmlFor="example-number-input"
                            className="col-sm-3 col-form-label"
                          >
                            Select Teacher
                          </label>
                          <div className="col-sm-9" style={{ display: "flex" }}>
                            <select
                              id="role"
                              className="form-control"
                              name="teacherId"
                              value={this.state.teacherId}
                              onChange={(e) => this.handleSelectChange(e)}
                            >
                              <option> Select Teachers</option>
                              {option}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="example-number-input"
                          className="col-sm-3 col-form-label"
                        >
                          Slug
                        </label>
                        <div className="col-sm-9">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="slug-url"
                            name="slug"
                            value={this.state.slug}
                            onChange={(e) => this.handleSelectChange(e)}
                          />
                        </div>
                      </div>
                    </div>

                    {/* If Live Session is Selected */}
                    <div
                      className={
                        this.state.courseType === "2" ||
                        this.state.courseType === "4"
                          ? "workshop-content-wrap"
                          : "workshop-content-wrap d-none"
                      }
                    >
                      <div className="form-group row">
                        <label
                          htmlFor="example-url-input"
                          className="col-sm-3 col-form-label "
                        >
                          Meeting Link
                        </label>
                        <div className="col-sm-9">
                          <input
                            className="form-control"
                            type="url"
                            placeholder="Enter Zoom Meeting link"
                            name="meetingLink"
                            value={this.state.meetingLink}
                            onChange={(e) => this.handleSelectChange(e)}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-3 col-form-label"
                        >
                          Meeting Password
                        </label>
                        <div className="col-sm-9">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Zoom Meeting password"
                            name="meetingPassword"
                            value={this.state.meetingPassword}
                            onChange={(e) => this.handleSelectChange(e)}
                          />
                        </div>
                      </div>
                      {/* <div className="form-group row">
                        <label
                          htmlFor="example-date-input"
                          className="col-sm-3 col-form-label "
                        >
                          Date
                        </label>
                        <div className="col-sm-9">
                          <DatePicker
                            className="form-control"
                            value={this.formatdate(this.state.dateTime)}
                            onChange={(e) => this.handleSelectChange(e)}
                          />
                        </div>
                      </div> */}
                      <div className="form-group row">
                        <label
                          htmlFor="example-date-input"
                          className="col-sm-3 col-form-label "
                        >
                          Date
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="date"
                            name="dateTime"
                            value={this.formatDate(this.state.dateTime)}
                            onChange={(e) => this.handleDateChange(e)}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-time-input"
                          className="col-sm-3 col-form-label "
                        >
                          Time
                        </label>
                        <div className="col-sm-9">
                          <input
                            className="form-control"
                            type="time"
                            name="time"
                            value={this.formatTime(this.state.dateTime)}
                            onChange={(e) => this.handleTimeChange(e)}
                          />
                        </div>
                      </div>
                    </div>

                    {/* If Master Class is Selected */}
                    {/* <div
                      className={
                        this.state.courseType === "3"
                          ? "workshop-content-wrap"
                          : "workshop-content-wrap d-none"
                      }
                    >
                      <div className="form-group row">
                        <label
                          htmlFor="example-number-input"
                          className="col-sm-3 col-form-label"
                        >
                          Video Upload
                        </label>
                        <div className="col-sm-9" style={{ display: "flex" }}>
                          <input
                            type="file"
                            className="form-control"
                            name="thumbnailUrl"
                            onChange={this.handleMasterClassVideoUrl.bind(this)}
                          />
                        </div>
                      </div>
                    </div> */}

                    {/* col-sm-8 Left Content Ends */}
                  </div>

                  {/* Row */}
                  <div className="col-sm-4">
                    {/* col-sm-4 Right Content Starts */}
                    <div id="mainApp"></div>
                  </div>
                  {/* col-sm-4 Right Content Ends*/}
                </div>
                {/* Main Row Ends */}
              </div>
              {visible ? (
                <button
                  type="button"
                  className="btn btn-labeled btn-success ml-2"
                  onClick={this.handleUpdateCourse}
                >
                  <span className="btn-label">
                    <i className="fas fa-check"></i>
                  </span>
                  Save Courses Update
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-labeled btn-success ml-2"
                  onClick={this.handleSaveDetails}
                >
                  <span className="btn-label">
                    <i className="fas fa-check"></i>
                  </span>
                  Save Course
                </button>
              )}

              {/* TAB - 2 */}
              <div
                className="tab-pane fade"
                id="pills-two"
                role="tabpanel"
                aria-labelledby="pills-two-tab"
              >
                <h6 className="font-weight-bold mb-3">About the course</h6>
                <About onSelectAbout={(e) => this.onChangeAbout(e)} />
              </div>

              {/* TAB - 3 */}
              <div
                className="tab-pane fade"
                id="pills-three"
                role="tabpanel"
                aria-labelledby="pills-three-tab"
              >
                {/**************************** Course Curriculum Starts ****************************/}
                <h6 className="font-weight-bold">Upload Course Curriculum</h6>
                <div className="course-cur-wrapper mt-4">
                  <div className="course-cur-card">
                    {/* <div className="text-right">
                              <button type="button" className="btn btn-success w-100p mb-2 mr-1" ><span className="typcn typcn-delete"></span> Upload Section</button>
                           </div> */}

                    <div className="form-group row">
                      {/* Heading Title Starts */}
                      <label className="col-sm-2 col-form-label" name="title">
                        Heading Title
                      </label>
                      <div className="col-sm-5">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Eg- Chapter-1, Part-1 or any title"
                          name="headingTitle"
                          value={this.state.headingTitle}
                          onChange={(e) => this.handleSelectChange(e)}
                        />
                      </div>
                    </div>
                    {/* Heading Title Ends */}
                    {this.createSubTitle()}
                    <button
                      type="button"
                      className="btn btn-success-soft w-100p ml-5"
                      onClick={this.handleAddSubtitle.bind(this)}
                    >
                      Add Sub-Title
                    </button>
                  </div>

                  {/******Course Curriculum List*******/}

                  <h6 className="font-weight-bold">Course Curriculum List</h6>

                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Heading Title</th>
                          <th>Sub Title</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {/******Loop Starts*******/}
                        {getlist.lectureEmbeddedCourse
                          ? getlist.lectureEmbeddedCourse.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <Link
                                    to={{
                                      pathname: `/admin/course/course-curr-edit`,
                                      state: row,
                                      maindata: this.props.location.state,
                                    }}
                                  >
                                    <button className="actions-btn">
                                      <span className="typcn typcn-edit"></span>
                                    </button>
                                  </Link>
                                  {row.headingTitle}
                                </td>
                                <td>
                                  {row.listOfLectures.map((prop, index) => (
                                    <tr key={index}>
                                      <td>{prop.subtitle}</td>
                                    </tr>
                                  ))}
                                </td>
                                <td>
                                  {row.listOfLectures.map((prop, index) => (
                                    <tr key={index}>
                                      <td>
                                        <span className="text-success">
                                          Uploaded
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </td>
                                <td>
                                  {row.listOfLectures.map((prop, index) => (
                                    <tr key={index}>
                                      <td>
                                        <button
                                          className="actions-btn"
                                          onClick={(e) =>
                                            this.handlDeleteById(prop.id)
                                          }
                                        >
                                          <i className="fas fa-trash-alt"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </td>
                              </tr>
                            ))
                          : ""}
                        {/******Loop Ends*******/}
                      </tbody>
                    </table>
                  </div>

                  {/******Course Curriculum List*******/}
                </div>
                {/**************************** Course Curriculum Ends ***************************/}
              </div>
            </div>
            <ToastContainer autoClose={1500} />
            {/* Form Fields Ends */}
            {/* Create New Workshop Ends*/}
          </div>
        </div>
      </div>
    );
  }
}
