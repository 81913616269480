import React, { Component } from 'react';
import './dashboard.css';



export default class Dashboard extends Component {
    render() {
        return (
            
<div>


<div className="body-content mt-3 bg-light-gray">


  <div className="row">

    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
      <div className="card card-stats statistic-box mb-4">
        <div className="card-header card-header-warning card-header-icon position-relative border-0 text-right px-3 py-0">
          <div className="card-icon d-flex align-items-center justify-content-center">
            <i className="typcn typcn-device-tablet" />
          </div>
          <p className="card-category text-uppercase fs-10 font-weight-bold text-muted">Total Revenue</p>
          <h3 className="card-title fs-18 font-weight-bold mb-3">€100</h3>
        </div>
      </div>
    </div>


    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
      <div className="card card-stats statistic-box mb-4">
        <div className="card-header card-header-warning card-header-icon position-relative border-0 text-right px-3 py-0">
          <div className="card-icon d-flex align-items-center justify-content-center">
            <i className="typcn typcn-device-tablet" />
          </div>
          <p className="card-category text-uppercase fs-10 font-weight-bold text-muted">Total Courses</p>
          <h3 className="card-title fs-18 font-weight-bold mb-3">10</h3>
        </div>
      </div>
    </div>


    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
      <div className="card card-stats statistic-box mb-4">
        <div className="card-header card-header-warning card-header-icon position-relative border-0 text-right px-3 py-0">
          <div className="card-icon d-flex align-items-center justify-content-center">
            <i className="typcn typcn-device-tablet" />
          </div>
          <p className="card-category text-uppercase fs-10 font-weight-bold text-muted">Total Customers</p>
          <h3 className="card-title fs-18 font-weight-bold mb-3">50</h3>
        </div>
      </div>
    </div>


    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
      <div className="card card-stats statistic-box mb-4">
        <div className="card-header card-header-warning card-header-icon position-relative border-0 text-right px-3 py-0">
          <div className="card-icon d-flex align-items-center justify-content-center">
            <i className="typcn typcn-device-tablet" />
          </div>
          <p className="card-category text-uppercase fs-10 font-weight-bold text-muted">Total User Roles</p>
          <h3 className="card-title fs-18 font-weight-bold mb-3">5</h3>
        </div>
      </div>
    </div>

  
  </div>
  
  
  
  

   {/* Footer */}
   <div className="header-footer">
      <div className="chart">
        <canvas id="forecast" width={300} height={100} />
      </div>
    </div>



 
  <div className="row">
    <div className="col-lg-8">
      <div className="card mb-4">
        <div className="card-header">
          <h2 className="fs-18 font-weight-bold mb-0">Bar chart</h2>
        </div>
        <div className="card-body">
          <canvas id="barChart" height={160} />
        </div>
      </div>
    </div>
   
  </div>
  
  
  
</div>




</div>


        );
    }
}

