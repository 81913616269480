import React, { Component } from 'react'
import logo from './logo-dark.png'

export default class Registration extends Component {
    handleSubmit(){
        window.location.href="/auth/login"
    }
    render() {
        return (
            <div>
                <div className="d-flex align-items-center justify-content-center text-center h-100vh">
                <div className="form-wrapper m-auto" >
                    <div className="form-container my-4">

                        <div className="panel">
                            <div className="panel-header text-center mb-3">
                                <img src={logo} className="brand_logo" alt="Logo" />

                                <h3 className="fs-24">Registration Complete</h3>
                                <p className="text-muted text-center mb-0">Congratulation your account registration is complete. You can now login into the admin portal.</p>
                                  <button type="submit" onClick={(e)=>this.handleSubmit()} className="btn btn-success btn-block mt-3">Continue login</button>
                            </div>

                            <p className="text-muted text-center mt-3 mb-2">Copyright © 2020 Hi-Tek Inc. All Rights Reserved</p>

                      
                        </div>

                    </div>
                </div>
            </div>
            </div>
        )
    }
}
