import Paper from "@material-ui/core/Paper";
import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../../api";

export default class Edit extends Component {
  constructor(props) {
    super(props);
    let userdata = this.props.location.state;
    this.state = {
      loading: false,
      id: userdata.id,
      email: userdata.email,
      firstName: userdata.firstName,
      phoneNo: userdata.phone.replace("+91", ""),
      gender: userdata.gender == "f" ? "Female" : "Male",
      lastName: userdata.lastName,
      address: userdata.address,
      role: userdata.role,
      password: "",
      confirmPassword: "",
      passworderror: "",
      confirmPassworderror: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  validate = () => {
    let confirmPassworderror = "";
    let passworderror = "";
    if (!this.state.password) {
      passworderror = "password cannot be blank";
    }
    if (!this.state.confirmPassword) {
      confirmPassworderror = "password cannot be blank";
    }
    if (confirmPassworderror || passworderror) {
      this.setState({
        confirmPassworderror: confirmPassworderror,
        passworderror: passworderror,
      });
      return false;
    }
    return true;
  };
  handleBack(e) {
    this.props.history.goBack();
  }
  handleUpdate(event) {
    event.preventDefault();
    new API()
      .getHttpClient()
      .patch("/user", {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        address: this.state.address,
        gender: this.state.gender.includes("F") ? "f" : "m",
        phone: this.state.phoneNo,
        userId: this.state.id,
        role: this.state.role,
      })
      .then((res) => {
        if (res) {
          toast.success("Successfully Updated");
          this.setState({ isLoaded: true, userdata: res.data });
          window.location = "/admin/customer/list";
        }
      })
      .catch((error) => {
        toast.error("error !!" + error);
      });
  }

  render() {
    return (
      <Paper>
        <div className="page-head">
          <div className="row">
            <div className="col-sm-6 text-head">
              <h3>Update User</h3>
            </div>
          </div>
        </div>

        <section className="panel">
          <div className="sub-heads row">
            <div className="col-sm-6 text-head">
              <h5>Showing user information</h5>
            </div>

            <div className="col-sm-6 text-right">
              <button
                className=" btn btn-light"
                onClick={(e) => this.handleBack(e)}
              >
                <span className="typcn typcn-arrow-left-thick"></span>Back
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 form-group">
              <label>First Name</label>
              <input
                type="text"
                className="form-control "
                name="firstName"
                value={this.state.firstName}
                onChange={this.handleChange}
              />
            </div>

            <div className="col-md-6 form-group">
              <label>Last Name</label>
              <input
                type="text"
                className="form-control "
                name="lastName"
                value={this.state.lastName}
                onChange={this.handleChange}
              />
            </div>

            <div className="form-group col-md-6">
              <label>Phone</label>
              <input
                type="number"
                className="form-control "
                id=""
                name="phoneNo"
                value={this.state.phoneNo}
                onChange={this.handleChange}
              />
            </div>

            <div className="form-group col-md-6">
              <label>Gender</label>
              <select
                id="gender"
                className="form-control"
                name="gender"
                value={this.state.gender}
                onChange={this.handleChange}
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>

            <div className="form-group col-md-6">
              <label>Email</label>
              <input
                type="text"
                className="form-control "
                id="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                disabled
              />
            </div>

            <div className="col-md-6 form-group">
              <label>Role</label>
              <select
                id="role"
                className="form-control"
                name="role"
                value={this.state.role}
                onChange={this.handleChange}
              >
                <option value="ADMIN">ADMIN</option>
                <option value="CUSTOMER">CUSTOMER</option>
                <option value="TEACHER">TEACHER</option>
              </select>
            </div>

            <div className="col-md-12 form-group">
              <label>Address</label>
              <textarea
                rows="4"
                className="form-control"
                type="text"
                name="address"
                value={this.state.address}
                onChange={this.handleChange}
              ></textarea>
            </div>
          </div>

          <button
            className=" btn btn-success col-sm-3 mt-3 py-2"
            onClick={this.handleUpdate}
          >
            Update
          </button>
          <ToastContainer autoClose={1500} />
        </section>
      </Paper>
    );
  }
}
