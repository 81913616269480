import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import Header from './header';
import Footer from './footer'
import SideBar from './sidebar';
import Courses from './courses';
import User from './user';
import dashboard from './dashboard';
import Customer from './customer';
import Coupons from './coupons';
import Payments from './payments';
import Comments from './comments';
import Blogs from './blogs';
import Newsletter from './newsletter';
import Course_Buy from './course-buy';
import Combo_Offers from './combo-offers';

export default class Main extends Component {
  render() {
    const { match } = this.props;
    return (
        <div className="wrapper">
          <Header />
          <div className="content-wrapper">
            <div className="main-content">
              <SideBar />
              <Switch>
                <Route exact path={[`${match.path}/dashboard`, `${match.path}`]} component={dashboard} />
                <Route path={`${match.path}/course`} component={Courses} />
                <Route path={`${match.path}/user`} component={User} />
                <Route path={`${match.path}/customer`} component={Customer} />
                <Route path={`${match.path}/coupons`} component={Coupons} />
                <Route path={`${match.path}/payments`} component={Payments} />
                <Route path={`${match.path}/comments`} component={Comments} />
                <Route path={`${match.path}/blogs`} component={Blogs} />
                <Route path={`${match.path}/newsletter`} component={Newsletter} />
                <Route path={`${match.path}/course-buy`} component={Course_Buy} />
                <Route path={`${match.path}/combo-offers`} component={Combo_Offers} />
              </Switch>
              <Footer />
            </div>
          </div>

        </div>
    );
  }
}